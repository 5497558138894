import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      'consent.status_deleting': 'Deleting!',
      'consent.status_deleted': 'Deleted!',
      'consent.status_saving' : 'Saving!',
      'consent.status_saved' : 'Saved!',
      'consent.minutes' : 'minutes',
      'consent.minute' : 'minute',
      'consent.hours' : 'hours',
      'consent.hour' : 'hour',
      'consent.years' : 'years',
      'consent.months' : 'months',
      'consent.weeks' : 'weeks',
      'consent.days' : 'days',
      'consent.1_day' : '1 day',
      'consent.does_not_expire' : 'Does not expire',
      'consent.times' : 'times',
      'consent.one_time_use' : 'One time use',
      'consent.no_record' : 'No record found',
      'consent.btn_delete_email' : 'DELETE EMAIL',
      'consent.btn_release_email' : 'RELEASE EMAIL',
      'consent.sender_domain_whitelist' : 'Whitelist all addresses sender-to-domain?',
      'consent.domain_domain_whitelist' : 'Whitelist all addresses domain-to-domain?',
      'consent.start_date' : 'Consent start date',
      'consent.relationship_expires' : 'Relationship (expires):',
      'consent.error_filesize' : 'File is too big! Max 5Mb',
      'consent.btn_remove' : 'REMOVE',
      'consent.btn_upload' : 'UPLOAD',
      'consent.document' : 'Supporting Document',
      'consent.account_notes' : 'Account notes',
      'consent.copy_above' : 'Copy consent from above?',
      'consent.overriding_unsubscribe_consent' : 'Overriding unsubscribe. Please provide consent below.',
      'consent.overriding_unsubscribe' : 'Overriding unsubscribe. Consent for this email recipient exists. Your email will be delivered.',
      'consent.override_unsubscribe' : 'Override unsubscribe? If unchecked, the recipient will automatically be removed from this email.',
      'consent.unsubscribed' : 'has unsubscribed',
      'consent.not_required' : 'All consent information is already recorded for this email. You can close the window',
      'consent.required' : 'Consent required:',
      'consent.exists' : 'Consent exists:',
      'consent.removal_reason' : 'Email deleted due to "{{reason}}". Please resend your email if it is still required.',
      'consent.btn_retry' : 'Retry',
      'consent.error' : 'Error:',
      'consent.fetching' : 'Fetching details ....',
      'consent.link_malformed' : 'This link is malformed. Please verify or retry',
      'consent.instructions' : 'Instructions at bottom of page.',
      'consent.title' : 'Email Consent Collection',
      'consent.saving' : 'Saving your consent data!',
      'consent.deleting' : 'Deleting yout email!',
      'consent.sent' : 'Consent has been recorded and your email has been released for delivery! You can close this window now.',
      'consent.deleted' : 'Your email has been deleted and will not be delivered! You can close this window now.',
      'consent.footnote1' : 'The email that you sent cannot be delivered to at least one of your recipients who does not have consent. If consent is not provided within {{expire}}, your email will be deleted. Please provide the required consent above. If you wish to cancel this email immediately, click the delete button at the bottom this page. PLEASE NOTE: Your email has not been delivered to any external recipients and is currently encrypted and temporarily delayed in a secure quarantine.',
      'consent.footnote2' : 'By providing consent and releasing this email, I certify that all information I provided on this form is true and correct to the best of my knowledge.',

      'unsubscribe.sending_title' : 'Sending request!',
      'unsubscribe.done' : 'Done!',
      'unsubscribe.sending' : 'Sending your unsubscribe request!',
      'unsubscribe.sent' : 'Your unsubscribe request has been sent to the email address that you have entered. You may now close this window.',
      'unsubscribe.request' : 'Unsubscribe Request',
      'unsubscribe.request2' : 'Unsubscribe Request Part 2',
      'unsubscribe.step1' : '<p>We’re sorry to see you go, but we need to send you an email confirming that you’re human and not a robot. The next email from us will have your official unsubscribe link.</p><p>Why do we do this? Sometimes people accidentally click unsubscribe links. Even worse, sometimes a “link-validation robot” clicks the link and unsubscribes you from emails that you want.</p>',
      'unsubscribe.step2' : '<p>Thank you for confirming your email. This is the last step.</p><p>Please let us know who you would like to unsubscribe from:</p>',
      'unsubscribe.address' : 'What is your email address?',
      'unsubscribe.error' : 'Error:',
      'unsubscribe.nolink' : 'This link is malformed. Please verify or retry',
      'unsubscribe.btn_email' : 'SEND UNSUBSCRIBE EMAIL',
      'unsubscribe.saved_title' : 'Saved!',
      'unsubscribe.saving_title': 'Saving!',
      'unsubscribe.saving' : 'Saving your Unsubscribe preference data!',
      'unsubscribe.saved' : 'Your unsubscribe preference is saved! You can close this window now.',
      'unsubscribe.fetching' : 'Fetching details ....',
      'unsubscribe.btn_retry' : 'Retry',
      'unsubscribe.btn_unsubscribe' : 'UNSUBSCRIBE',

      'subscribe.sending_title' : 'Sending request!',
      'subscribe.done' : 'Done!',
      'subscribe.sending' : 'Sending your subscribe request!',
      'subscribe.sent' : 'Your subscribe confirmation request has been sent to the email address that you have entered. You may now close this window.',
      'subscribe.request' : 'Subscribe',
      'subscribe.confirm' : 'Success',
      'subscribe.emailAddress' : 'Email Address',
      'subscribe.firstName' : 'First Name',
      'subscribe.lastName' : 'Last Name',
      'subscribe.company' : 'Company Name',
      'subscribe.error' : 'Error:',
      'subscribe.nolink' : 'This link is malformed. Please verify or retry',
      'subscribe.btn_email' : 'SUBSCRIBE',
      'subscribe.saved_title' : 'Saved!',
      'subscribe.saving_title': 'Saving!',
      'subscribe.saving' : 'Saving your subscribe preference data!',
      'subscribe.fetching' : 'Fetching details ....',
      'subscribe.btn_retry' : 'Retry',
      'subscribe.btn_subscribe' : 'SUBSCRIBE',

    }
  },
  fr: {
    translation: {
      "consent.status_deleting": "Suppression!",
      "consent.status_deleted": "Supprimé!",
      "consent.status_saving" : "Enregistrement de fichier!",
      "consent.status_saved" : "Enregistré!",
      "consent.minutes" : "minutes",
      "consent.minute" : "minute",
      "consent.hours" : "heures",
      "consent.hour" : "heure",
      "consent.years" : "ans",
      "consent.months" : "mois",
      "consent.weeks" : "semaines",
      "consent.days" : "journées",
      "consent.1_day" : "Un jour",
      "consent.does_not_expire" : "N'expire pas",
      "consent.times" : "fois",
      "consent.one_time_use" : "Utilisation unique",
      "consent.no_record" : "Aucun Enregistrement Trouvé",
      "consent.btn_delete_email" : "SUPPRIMER LE COURRIEL",
      "consent.btn_release_email" : "ENVOYER LE COURRIEL",
      "consent.sender_domain_whitelist" : "Liste blanche de toutes les adresses expéditeur vers domaine?",
      "consent.domain_domain_whitelist" : "Ajouter à la liste blanche toutes les adresses de domaine à domaine?",
      "consent.start_date" : "Date du début du consentement",
      "consent.relationship_expires" : "Relation avec le destinataire courriel:",
      "consent.error_filesize" : "Le fichier est trop volumineux! 5 Mo maximum",
      "consent.btn_remove" : "RETIRER",
      "consent.btn_upload" : "TÉLÉCHARGER",
      "consent.document" : "Documents de soutien",
      "consent.account_notes" : "Notes à propos du compte",
      "consent.copy_above" : "Copier le consentement ci-dessus ?",
      "consent.overriding_unsubscribe_consent" : "Annulation de la désinscription. Veuillez donner votre consentement ci-dessous.",
      "consent.overriding_unsubscribe" : "Annulation de la désinscription. Le consentement pour ce destinataire d'e-mail existe. Votre e-mail sera livré.",
      "consent.override_unsubscribe" : "Ignorer la désinscription ? Si elle n'est pas cochée, le destinataire sera automatiquement supprimé de cet e-mail.",
      "consent.unsubscribed" : "s'est désabonné",
      "consent.not_required" : "Toutes les informations de consentement sont déjà enregistrées pour cet e-mail. Vous pouvez fermer la fenêtre",
      "consent.required" : "Destinataires courriel requérant un consentement:",
      "consent.exists" : "Destinataires courriel ayant fourni un consentement:",
      "consent.removal_reason" : "E-mail supprimé en raison de \"{{reason}}\". Veuillez renvoyer votre e-mail s'il est toujours nécessaire.",
      "consent.btn_retry" : "Recommencez",
      "consent.error" : "Erreur:",
      "consent.fetching" : "Récupération des détails...",
      "consent.link_malformed" : "Ce lien est malformé. Veuillez vérifier ou réessayer",
      "consent.instructions" : "Instructions au bas de la page",
      "consent.title" : "Collecte de consentements pour courriel",
      "consent.saving" : "Enregistrement de vos données de consentement!",
      "consent.deleting" : "Suppression de votre e-mail!",
     "consent.sent" : "Le consentement a été enregistré et votre e-mail a été libéré pour livraison ! Vous pouvez fermer cette fenêtre maintenant.",
      "consent.deleted" : "Votre e-mail a été supprimé et ne sera pas livré ! Vous pouvez fermer cette fenêtre maintenant.",
      "consent.footnote1" : "Votre courriel n’a pas été envoyé, un de vos destinataires n’ayant pas fourni le consentement requis. Si aucun consentement n’est fourni d’ici quatre {{expire}} heures suivant l’envoi du courriel, votre courriel sera supprimé.",
      "consent.footnote2" : "Je certifie, ayant obtenu le consentement et par l’envoi de ce courriel, que toute l’information que j’ai inscrit sur ce formulaire est véridique et exacte, au meilleur de mes connaissances. Je comprends que j’utilise cette plateforme courriel à des fins commerciales légitimes et que je ne ferai ni un mauvais usage ni d’abus de cette plateforme à des fins non-autorisées.",

      "unsubscribe.sending_title" : " Envoyer une requete!",
      "unsubscribe.done" : "Fini!",
      "unsubscribe.sending" : "Envoi de votre demande de désinscription!",
      "unsubscribe.sent" : "Votre demande de désabonnement a été envoyée à l'adresse e-mail que vous avez saisie. Vous pouvez maintenant fermer cette fenêtre.",
      "unsubscribe.request" : "Demande de désabonnement",
      "unsubscribe.request2" : "Demande de désabonnement Partie 2",
      'unsubscribe.step1' : '<p>Nous sommes désolés de vous voir partir mais nous devons vous envoyer un courriel afin de confirmer que vous êtes une personne réelle et non un robot. Vous recevrez un courriel comprenant votre lien de désabonnement.</p><p>Pourquoi procédons-nous ainsi? Il arrive parfois que les gens cliquent accidentellement sur des liens de désabonnement. Ou, pire encore, des robots de validation de liens cliquent sur des liens, provoquant le désabonnement des courriels que vous désirez recevoir.</p>',
      'unsubscribe.step2' : '<p>Merci d’avoir confirmé votre courriel. Voici la dernière étape.</p><p>Veuillez nous spécifier de qui vous souhaitez vous désabonne:</p>',
      "unsubscribe.address" : "Quelle est votre adresse courriel?",
      "unsubscribe.error" : "Erreur:",
      "unsubscribe.nolink" : "Ce lien est malformé. Veuillez vérifier ou réessayer",
      "unsubscribe.btn_email" : "ENVOYER LE LIEN DE DÉSABONNEMENT",
      "unsubscribe.saved_title" : "Enregistré!",
      "unsubscribe.saving_title": "Économie!",
      "unsubscribe.saving" : "Enregistrement de vos données de préférence de désabonnement!",
      "unsubscribe.saved" : "Votre préférence de désinscription est enregistrée ! Vous pouvez fermer cette fenêtre maintenant.",
      "unsubscribe.fetching" : "Récupération des détails …",
      "unsubscribe.btn_retry" : "Recommencez",
      "unsubscribe.btn_unsubscribe" : "SE DÉSABONNER",

      "subscribe.sending_title" : "Envoyer une requete!",
      "subscribe.done" : "Fini!",
      "subscribe.sending" : "Envoi de votre demande de désinscription!",
      "subscribe.sent" : "Votre demande de désabonnement a été envoyée à l'adresse e-mail que vous avez saisie. Vous pouvez maintenant fermer cette fenêtre.",
      "subscribe.request" : "S’abonner",
          'subscribe.confirm': 'Succès',
      "subscribe.emailAddress" : "Adresse de courriel",
      "subscribe.firstName" : "Prénom",
      "subscribe.lastName" : "Nom de famille",
      "subscribe.company" : "Nom de l’entreprise",
      "subscribe.error" : "Erreur:",
      "subscribe.nolink" : "Ce lien est malformé. Veuillez vérifier ou réessayer",
      "subscribe.btn_email" : "S’ABONNER",
      "subscribe.saved_title" : "Enregistré!",
      "subscribe.saving_title": "Économie!",
      "subscribe.saving" : "Enregistrement de vos données de préférence de désabonnement!",
      "subscribe.saved" : "Votre préférence de désinscription est enregistrée ! Vous pouvez fermer cette fenêtre maintenant.",
      "subscribe.fetching" : "Récupération des détails …",
      "subscribe.btn_retry" : "Recommencez",
      "subscribe.btn_subscribe" : "SE DÉSABONNER",



    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    interpolation: {
      escapeValue: false // react already safes from xss

    }
  });

  export default i18n;
