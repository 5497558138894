import axios from "axios";
import FileSaver from 'file-saver';

import { authIsMissing } from "./auth";

export const FETCH_UNSUBSCRIBE_MANAGER_DATA =
  "UNSUBSCRIBE_MANAGER/FETCH_UNSUBSCRIBE_MANAGER_DATA";
export const FETCH_UNSUBSCRIBE_MANAGER_DATA_SUCCESS =
  "UNSUBSCRIBE_MANAGER/FETCH_UNSUBSCRIBE_MANAGER_DATA_SUCCESS";
export const FETCH_UNSUBSCRIBE_MANAGER_DATA_FAILED =
  "UNSUBSCRIBE_MANAGER/FETCH_UNSUBSCRIBE_MANAGER_DATA_FAILED";

export const FETCH_UNSUBSCRIBE_MANAGER_XLS =
  "UNSUBSCRIBE_MANAGER/FETCH_UNSUBSCRIBE_MANAGER_XLS";
export const FETCH_UNSUBSCRIBE_MANAGER_XLS_SUCCESS =
  "UNSUBSCRIBE_MANAGER/FETCH_UNSUBSCRIBE_MANAGER_XLS_SUCCESS";
export const FETCH_UNSUBSCRIBE_MANAGER_XLS_FAILED =
  "UNSUBSCRIBE_MANAGER/FETCH_UNSUBSCRIBE_MANAGER_XLS_FAILED";

export const SAVE_UNSUBSCRIBE_ENTRY =
  "UNSUBSCRIBE_MANAGER/SAVE_UNSUBSCRIBE_ENTRY";
export const SAVE_UNSUBSCRIBE_ENTRY_SUCCESS =
  "UNSUBSCRIBE_MANAGER/SAVE_UNSUBSCRIBE_ENTRY_FAILED_SUCCESS";
export const SAVE_UNSUBSCRIBE_ENTRY_FAILED =
  "UNSUBSCRIBE_MANAGER/SAVE_UNSUBSCRIBE_ENTRY_FAILED";
export const CLOSE_SAVE_UNSUBSCRIBE_MODAL =
  "UNSUBSCRIBE_MANAGER/CLOSE_SAVE_UNSUBSCRIBE_MODAL";

export const CLOSE_DELETE_CONFIRM_MODAL =
  "UNSUBSCRIBE_MANAGER/CLOSE_DELETE_CONFIRM_MODAL";

export const CLEAR_UNSUBSCRIBE_SAVE_ERROR =
  "UNSUBSCRIBE_MANAGER/CLEAR_UNSUBSCRIBE_SAVE_ERROR";

export const DELETE_RECORD = "UNSUBSCRIBE_MANAGER/DELETE_RECORD";

export const DELETE_RECORD_SUCCESS =
  "UNSUBSCRIBE_MANAGER/DELETE_RECORD_SUCCESS";
export const DELETE_RECORD_FAILED = "UNSUBSCRIBE_MANAGER/DELETE_RECORD_FAILED";
export const CLEAR_DELETE_RECORD_ERROR =
  "UNSUBSCRIBE_MANAGER/CLEAR_DELETE_RECORD_ERROR";
export const CLEAR_ERRORS = "UNSUBSCRIBE_MANAGER/CLEAR_ERRORS";

const initialState = {
  fetchLoading: false,
  fetchData: [],
  fetchPages: null,
  fetchError: null,
  unsubscribeSaveError: null,
  savingUnsubscribeEntry: false,
  showUnsubscribeSaveModal: false,
  showDeleteConfirmModal: false,
  deletingRecord: false,
  deleteRecordError: null,
  fetchingXls: false,
};

let abortController; // Module scoped abort controller
export const fetchData = (dispatch) => (
  page,
  pageSize,
  domain,
  recipient
) => {

  dispatch({
    type: FETCH_UNSUBSCRIBE_MANAGER_DATA,
  });

  // api notes
  // filter=1 sender-domain -> domain unsubscribe
  // filter=2 sender email -> recipient email

  let filter = null;

  const accessToken = sessionStorage.getItem("accessToken");

  if (
    accessToken === null ||
    accessToken === "" ||
    accessToken === "undefined"
  ) {
    sessionStorage.removeItem("accessToken");
    return dispatch(authIsMissing());
  }

  const params = {
    page,
    pageSize,
  };

  // add domain if not default
  if (!!domain && domain !== -1) {
    params["domainId"] = domain;
  }

  if (!!recipient && recipient !== "") {
    params["recipient"] = recipient;
  }

  if (filter) {
    params["filter"] = filter;
  }

  if (abortController) {
    abortController.abort(); // Tell the browser to abort request
  }
  abortController = typeof 'AbortController' !== 'undefined' && new AbortController();

  return axios
    .get(`${process.env.REACT_APP_API_HOST}/unsubscribed`, {
      params,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      signal: abortController.signal,
    })
    .then((res) => {
      // add index to each row
      const unsubscribeData = res.data.data.results.map((row, idx) => {
        return { ...row, rowIndex: idx };
      });
      return dispatch({
        type: FETCH_UNSUBSCRIBE_MANAGER_DATA_SUCCESS,
        data: unsubscribeData,
        pages: res.data.data.pageCount,
		    pageSize: res.data.data.pageSize,
      });
    })
    .catch((err) => {
      if (
        err !== undefined &&
        err.response !== undefined &&
        err.response.status === 401
      ) {
        // lets authenticate again
        sessionStorage.removeItem("accessToken");
        return dispatch(authIsMissing());
      }
      return dispatch({
        type: FETCH_UNSUBSCRIBE_MANAGER_DATA_FAILED,
        error: err,
      });
    });
};

export const fetchXls = (dispatch) => (
  domain,
  recipient
) => {

  dispatch({
    type: FETCH_UNSUBSCRIBE_MANAGER_XLS,
  });

  // api notes
  // filter=1 sender-domain -> domain unsubscribe
  // filter=2 sender email -> recipient email

  let filter = null;

  const accessToken = sessionStorage.getItem("accessToken");

  if (
    accessToken === null ||
    accessToken === "" ||
    accessToken === "undefined"
  ) {
    sessionStorage.removeItem("accessToken");
    return dispatch(authIsMissing());
  }

  const params = {
    export: 1,
  };

  // add domain if not default
  if (!!domain && domain !== -1) {
    params["domainId"] = domain;
  }

  if (!!recipient && recipient !== "") {
    params["recipient"] = recipient;
  }

  if (filter) {
    params["filter"] = filter;
  }

  return axios
    .get(`${process.env.REACT_APP_API_HOST}/unsubscribed`, {
      params,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      responseType: 'blob',
    })
    .then((res) => {

      FileSaver.saveAs(res.data, "unsubscribed.csv");
      
      return dispatch({
        type: FETCH_UNSUBSCRIBE_MANAGER_XLS_SUCCESS,
      });
    })
    .catch((err) => {
      if (
        err !== undefined &&
        err.response !== undefined &&
        err.response.status === 401
      ) {
        // lets authenticate again
        sessionStorage.removeItem("accessToken");
        return dispatch(authIsMissing());
      }
      return dispatch({
        type: FETCH_UNSUBSCRIBE_MANAGER_XLS_FAILED,
        error: err,
      });
    });
};

export const deleteUnsubscribeEntry = (dispatch) => (dataID, reason, supportingDocuments) => {
  dispatch({
    type: DELETE_RECORD,
  });
  const accessToken = sessionStorage.getItem("accessToken");
  if (
    accessToken === null ||
    accessToken === "" ||
    accessToken === "undefined"
  ) {
    sessionStorage.removeItem("accessToken");
    return dispatch(authIsMissing());
  }

  let bodyFormData = new FormData();
  bodyFormData.set("id", dataID);
  if(reason.length){
    bodyFormData.set("removal_reason", reason);
  }
  if (supportingDocuments.length > 0) {
    bodyFormData.append("unsubscribed_document", supportingDocuments[0]);
  }

  return axios({
    method: "post",
    url: `${process.env.REACT_APP_API_HOST}/unsubscribed/remove`,
    data: bodyFormData,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
    },
  })
    .then((res) => {
      return dispatch({
        type: DELETE_RECORD_SUCCESS,
      });
    })
    .catch((err) => {
      if (
        err !== undefined &&
        err.response !== undefined &&
        err.response.status === 401
      ) {
        // lets authenticate again
        sessionStorage.removeItem("accessToken");
        return dispatch(authIsMissing());
      }
      return dispatch({
        type: DELETE_RECORD_FAILED,
        error: err,
      });
    });
};

export const saveUnsubscribeEntry = (dispatch) => (data) => {
  dispatch({
    type: SAVE_UNSUBSCRIBE_ENTRY,
  });

  const accessToken = sessionStorage.getItem("accessToken");

  if (
    accessToken === null ||
    accessToken === "" ||
    accessToken === "undefined"
  ) {
    sessionStorage.removeItem("accessToken");
    return dispatch(authIsMissing());
  }

  return axios({
    method: "post",
    url: `${process.env.REACT_APP_API_HOST}/unsubscribed/create`,
    data,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
    },
  })
    .then((res) => {
      return dispatch({
        type: SAVE_UNSUBSCRIBE_ENTRY_SUCCESS,
      });
    })
    .catch((err) => {
      if (
        err !== undefined &&
        err.response !== undefined &&
        err.response.status === 401
      ) {
        // lets authenticate again
        sessionStorage.removeItem("accessToken");
        return dispatch(authIsMissing());
      }
      return dispatch({
        type: SAVE_UNSUBSCRIBE_ENTRY_FAILED,
        error: err,
      });
    });
};

export const closeSaveUnsubscribeModal = () => {
  return {
    type: CLOSE_SAVE_UNSUBSCRIBE_MODAL,
  };
};

export const closeDeleteConfirmModal = () => {
  return {
    type: CLOSE_DELETE_CONFIRM_MODAL,
  };
};

export const clearDeleteRecordError = () => {
  return {
    type: CLEAR_DELETE_RECORD_ERROR,
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_ERRORS:
      return {
        ...state,
        fetchError: null,
        unsubscribeSaveError: null,
        deleteRecordError: null,
      }; 
    case FETCH_UNSUBSCRIBE_MANAGER_DATA:
      return {
        ...state,
        fetchLoading: true,
        fetchData: [],
        fetchError: null,
      };
    case FETCH_UNSUBSCRIBE_MANAGER_DATA_SUCCESS:
      return {
        ...state,
        fetchLoading: false,
        fetchData: action.data,
        fetchPages: action.pages,
        fetchError: null,
      };
    case FETCH_UNSUBSCRIBE_MANAGER_DATA_FAILED:
      return {
        ...state,
        fetchLoading: false,
        fetchData: [],
        fetchError: action.error,
      };

    case FETCH_UNSUBSCRIBE_MANAGER_XLS:
      return {
        ...state,
        fetchingXls: true,
      };
    case FETCH_UNSUBSCRIBE_MANAGER_XLS_SUCCESS:
      return {
        ...state,
        fetchingXls: false,
      };
    case FETCH_UNSUBSCRIBE_MANAGER_XLS_FAILED:
      return {
        ...state,
        fetchingXls: false,
      };

    case SAVE_UNSUBSCRIBE_ENTRY:
      return {
        ...state,
        savingUnsubscribeEntry: true,
        showUnsubscribeSaveModal: true,
        unsubscribeSaveError: null,
      };

    case SAVE_UNSUBSCRIBE_ENTRY_SUCCESS:
      return {
        ...state,
        savingUnsubscribeEntry: false,
        unsubscribeSaveError: null,
      };

    case SAVE_UNSUBSCRIBE_ENTRY_FAILED:
      return {
        ...state,
        savingUnsubscribeEntry: false,
        showUnsubscribeSaveModal: false,
        unsubscribeSaveError: action.error,
      };

    case CLEAR_UNSUBSCRIBE_SAVE_ERROR:
      return {
        ...state,
        unsubscribeSaveError: null,
      };

    case CLOSE_DELETE_CONFIRM_MODAL:
      return {
        ...state,
        showDeleteConfirmModal: false,
      };

    case CLOSE_SAVE_UNSUBSCRIBE_MODAL:
      return {
        ...state,
        showUnsubscribeSaveModal: false,
      };

    case DELETE_RECORD:
      return {
        ...state,
        deletingRecord: true,
        showDeleteConfirmModal: true,
      };

    case DELETE_RECORD_SUCCESS:
      return {
        ...state,
        deletingRecord: false,
        deleteRecordError: null,
      };

    case DELETE_RECORD_FAILED:
      return {
        ...state,
        deletingRecord: false,
        deleteRecordError: action.error,
      };

    case CLEAR_DELETE_RECORD_ERROR:
      return {
        ...state,
        deleteRecordError: null,
      };

    default:
      return state;
  }
};
