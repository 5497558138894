import axios from "axios";
import { authIsMissing } from "./auth";

export const FETCH_DASHBOARD_DATA = "DASHBOARD/FETCH_DASHBOARD_DATA";
export const FETCH_DASHBOARD_DATA_SUCCESS =
  "DASHBOARD/FETCH_DASHBOARD_DATA_SUCCESS";
export const FETCH_DASHBOARD_DATA_FAILED =
  "DASHBOARD/FETCH_DASHBOARD_DATA_FAILED";

const initialState = {
  loading: false,
  data: {},
  error: null,
};

let abortController; // Module scoped abort controller
export const fetchData = (dispatch) => (domain) => {
  dispatch({
    type: FETCH_DASHBOARD_DATA,
  });
  const accessToken = sessionStorage.getItem("accessToken");

  if (
    accessToken === null ||
    accessToken === "" ||
    accessToken === "undefined"
  ) {
    sessionStorage.removeItem("accessToken");
    return dispatch(authIsMissing());
  }
  let params = {};
  // add domain if not default
  if (!!domain && domain !== -1) {
    params["domainId"] = domain;
  }

  if (abortController) {
    abortController.abort(); // Tell the browser to abort request
  }
  abortController = typeof 'AbortController' !== 'undefined' && new AbortController();

  return axios
    .get(`${process.env.REACT_APP_API_HOST}/dashboard`, {
      params,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      signal: abortController.signal,
    })
    .then((res) => {
      // add index to each row
      // calculate total for country list
      const locationTotal = Object.keys(res.data.data.locations).reduce(
        (total, key) => {
          total += parseInt(res.data.data.locations[key]);
          return total;
        },
        0
      );

      return dispatch({
        type: FETCH_DASHBOARD_DATA_SUCCESS,
        data: { ...res.data.data, locationTotal },
        pages: res.data.data.totalCount,
      });
    })
    .catch((err) => {
      if (
        err !== undefined &&
        err.response !== undefined &&
        err.response.status === 401
      ) {
        // lets authenticate again
        sessionStorage.removeItem("accessToken");
        return dispatch(authIsMissing());
      }
      return dispatch({
        type: FETCH_DASHBOARD_DATA_FAILED,
        error: err,
      });
    });
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DASHBOARD_DATA:
      return {
        ...state,
        loading: true,
        data: {},
        error: null,
      };
    case FETCH_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        pages: action.pages,
        error: null,
      };
    case FETCH_DASHBOARD_DATA_FAILED:
      return {
        ...state,
        loading: false,
        data: {},
        error: action.error,
      };

    default:
      return state;
  }
};
