import axios from "axios";
import FileSaver from 'file-saver';

import moment from "moment";

import { authIsMissing } from "./auth";

export const FETCH_DMARC_DATA = "DMARC/FETCH_DMARC_DATA";
export const FETCH_DMARC_DATA_SUCCESS = "DMARC/FETCH_DMARC_DATA_SUCCESS";
export const FETCH_DMARC_DATA_FAILED = "DMARC/FETCH_DMARC_DATA_FAILED";

export const FETCH_DMARC_XLS = "DMARC/FETCH_DMARC_XLS";
export const FETCH_DMARC_XLS_SUCCESS = "DMARC/FETCH_DMARC_XLS_SUCCESS";
export const FETCH_DMARC_XLS_FAILED = "DMARC/FETCH_DMARC_XLS_FAILED";

export const SET_START_DATE = "DMARC/SET_START_DATE";
export const SET_END_DATE = "DMARC/SET_END_DATE";
export const CLEAR_ERRORS = "DMARC/CLEAR_ERRORS";


const initialState = {
  loading: false,
  data: [],
  error: null,
  startDate: null,
  endDate: null,
  pages: -1,
  pageSize: 20,
  fetchingXls: false,
};

let abortController; // Module scoped abort controller
export const fetchData = (dispatch) => (
  startDate,
  endDate,
  page,
  pageSize,
  domain
) => {
  dispatch({
    type: FETCH_DMARC_DATA,
  });

  const accessToken = sessionStorage.getItem("accessToken");
  if (
    accessToken === null ||
    accessToken === "" ||
    accessToken === "undefined"
  ) {
    sessionStorage.removeItem("accessToken");
    return dispatch(authIsMissing());
  }
  let params = {
    page,
    pageSize,
  };

  if (!!domain && domain !== -1) {
    params["domainId"] = domain;
  }

  if (startDate !== null && endDate != null) {
    params["dateFrom"] = moment(startDate).format("YYYY-MM-DD");
    params["dateTo"] = moment(endDate).format("YYYY-MM-DD");
  }

  if (abortController) {
    abortController.abort(); // Tell the browser to abort request
  }
  abortController = typeof 'AbortController' !== 'undefined' && new AbortController();

  return axios
    .get(`${process.env.REACT_APP_API_HOST}/dmarc-report`, {
      params,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      signal: abortController.signal,
    })
    .then((res) => {
      const dmarcData = res.data.data.results.map((row, idx) => {
        return { ...row, rowIndex: idx };
      });
      return dispatch({
        type: FETCH_DMARC_DATA_SUCCESS,
        data: dmarcData,
        pages: res.data.data.pageCount,
        pageSize: res.data.data.pageSize,
      });
    })
    .catch((err) => {
      if (
        err !== undefined &&
        err.response !== undefined &&
        err.response.status === 401
      ) {
        // lets authenticate again
        sessionStorage.removeItem("accessToken");
        return dispatch(authIsMissing());
      }
      return dispatch({
        type: FETCH_DMARC_DATA_FAILED,
        error: err,
      });
    });
};


export const fetchXls = (dispatch) => (
  startDate,
  endDate,
  domain
) => {
  dispatch({
    type: FETCH_DMARC_XLS,
  });

  const accessToken = sessionStorage.getItem("accessToken");
  if (
    accessToken === null ||
    accessToken === "" ||
    accessToken === "undefined"
  ) {
    sessionStorage.removeItem("accessToken");
    return dispatch(authIsMissing());
  }
  let params = {
    export: 1
  };

  if (!!domain && domain !== -1) {
    params["domainId"] = domain;
  }

  if (startDate !== null && endDate != null) {
    params["dateFrom"] = moment(startDate).format("YYYY-MM-DD");
    params["dateTo"] = moment(endDate).format("YYYY-MM-DD");
  }

  return axios
    .get(`${process.env.REACT_APP_API_HOST}/dmarc-report`, {
      params,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      responseType: 'blob',
    })
    .then((res) => {

      FileSaver.saveAs(res.data, "dmarc-report.csv");

      return dispatch({
        type: FETCH_DMARC_XLS_SUCCESS,
      });
    })
    .catch((err) => {
      if (
        err !== undefined &&
        err.response !== undefined &&
        err.response.status === 401
      ) {
        // lets authenticate again
        sessionStorage.removeItem("accessToken");
        return dispatch(authIsMissing());
      }
      return dispatch({
        type: FETCH_DMARC_XLS_FAILED,
        error: err,
      });
    });
};


export const setStartDate = (d) => {
  return {
    type: SET_START_DATE,
    data: d,
  };
};

export const setEndDate = (d) => {
  return {
    type: SET_END_DATE,
    data: d,
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case FETCH_DMARC_DATA:
      return {
        ...state,
        loading: true,
        data: [],
        error: null,
      };
    case FETCH_DMARC_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        pages: action.pages,
        error: null,
      };
    case FETCH_DMARC_DATA_FAILED:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.error,
      };

    case FETCH_DMARC_XLS:
      return {
        ...state,
        fetchingXls: true,
      };
    case FETCH_DMARC_XLS_SUCCESS:
      return {
        ...state,
        fetchingXls: false,
      };
    case FETCH_DMARC_XLS_FAILED:
      return {
        ...state,
        fetchingXls: false,
      };

    case SET_START_DATE:
      return {
        ...state,
        startDate: action.data,
      };

    case SET_END_DATE:
      return {
        ...state,
        endDate: action.data,
      };

    default:
      return state;
  }
};
