import React, { Component, Suspense } from "react";
import { PersistGate } from "redux-persist/integration/react";
import { Router, Route, Switch /* Redirect */ } from "react-router-dom";

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import configureStore from "store";

const AuthLayout = React.lazy(() => import('layouts/Auth.jsx'));
const ConsentCollector = React.lazy(() => import('components/ConsentCollector'));
const ConsentEntry = React.lazy(() => import('components/ConsentEntry'));
const UnsubscribePart1 = React.lazy(() => import('components/UnsubscribePart1'));
const UnsubscribePart2 = React.lazy(() => import('components/UnsubscribePart2'));
const SubscribePart1 = React.lazy(() => import('components/SubscribePart1'));
const SubscribePart2 = React.lazy(() => import('components/SubscribePart2'));
const Home = React.lazy(() => import('containers/Home'));

const hist = createBrowserHistory();

class App extends Component {

  render() {
    const { store, persistor } = configureStore();
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router history={hist}>
		        <Suspense fallback={<div>Loading...</div>}>
              <Switch>
                <Route path="/auth" render={(props) => <GoogleReCaptchaProvider reCaptchaKey="6LcBockZAAAAABxE-uvTTDOMdmX4tAiUU_oY-6f9"><AuthLayout {...props} /></GoogleReCaptchaProvider> } />
                <Route exact path="/consent-collector" render={(props) => <ConsentCollector {...props} /> } />
                <Route exact path="/consent-entry" render={(props) => <ConsentEntry {...props} /> } />
                <Route exact path="/unsubscribe/step-1" render={(props) => <UnsubscribePart1 {...props} /> } />
                <Route exact path="/unsubscribe/step-2" render={(props) => <UnsubscribePart2 {...props} /> } />
                <Route exact path="/subscribe/step-1" render={(props) => <SubscribePart1 {...props} /> } />
                <Route exact path="/subscribe/step-2" render={(props) => <SubscribePart2 {...props} /> } />
                <Route path="/" render={(props) => <Home {...props} /> } />
              </Switch>
            </Suspense>
          </Router>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
