// import { AUTH_START } from "../actions/auth";

import axios from "axios";

import qs from "querystring";

export const LOGIN = "AUTH/LOGIN";

export const LOGIN_SUCCESS = "AUTH/LOGIN_SUCCESS";
export const LOGIN_FAILED = "AUTH/LOGIN_FAILED";
export const LOGOUT = "AUTH/LOGOUT";
export const AUTH_MISSING_ERROR = "AUTH/MISSING_ERROR";

export const FETCH_USER_DATA = "USER/FETCH_USER_DATA";
export const FETCH_USER_DATA_SUCCESS = "USER/FETCH_USER_DATA_SUCCESS";
export const FETCH_USER_DATA_FAILED = "USER/FETCH_USER_DATA_FAILED";

export const FETCH_USER_DOMAIN = "USER/FETCH_USER_DOMAIN";
export const FETCH_USER_DOMAIN_SUCCESS = "USER/FETCH_USER_DOMAIN_SUCCESS";
export const FETCH_USER_DOMAIN_FAILED = "USER/FETCH_USER_DOMAIN_FAILED";

export const SET_DOMAIN = "USER/SET_DOMAIN";

const initialState = {
  loggingIn: false,
  user: null,
  error: null,
  missingError: null,
  fetchingDomains: false,
  domains: [],
  domainLoadError: null,
  selectedDomain: '',
};

export const authIsMissing = () => {
  return {
    type: AUTH_MISSING_ERROR,
  };
};

export const login = (dispatch) => (username, password, tfa_code, recaptcha) => {
  dispatch({
    type: LOGIN,
  });

  const apiHost = process.env.REACT_APP_API_HOST;

  return axios
    .post(
      `${apiHost}/user/login`,
      qs.stringify({
        username,
        password,
        tfa_code,
        recaptcha
      }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    )
    .then((res) => {
      // set access token
      sessionStorage.setItem("accessToken", res.data.data.accessToken);
      dispatch({
        type: LOGIN_SUCCESS,
        user: res.data.data,
      });
    })
    .catch((err) => {
      return dispatch({
        type: LOGIN_FAILED,
        error: err,
      });
    });
};

export const logout = (d) => {
  sessionStorage.removeItem("accessToken");
  return {
    type: LOGOUT,
  };
};

export const fetchDomains = (dispatch) => () => {
  dispatch({
    type: FETCH_USER_DOMAIN,
  });

  const accessToken = sessionStorage.getItem("accessToken");
  if (
    accessToken === null ||
    accessToken === "" ||
    accessToken === "undefined"
  ) {
    sessionStorage.removeItem("accessToken");
    return dispatch(authIsMissing());
  }
  let params = {};
  params['pageSize'] = 1000;

  return axios
    .get(`${process.env.REACT_APP_API_HOST}/domain`, {
      params,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then((res) => {
      const domains = [
        ...res.data.data.results,
      ];
      return dispatch({
        type: FETCH_USER_DOMAIN_SUCCESS,
        data: domains,
      });
    })
    .catch((err) => {
      if (
        err !== undefined &&
        err.response !== undefined &&
        err.response.status === 401
      ) {
        // lets authenticate again
        sessionStorage.removeItem("accessToken");
        return dispatch(authIsMissing());
      }
      return dispatch({
        type: FETCH_USER_DOMAIN_FAILED,
        error: err,
      });
    });
};

export const setDomain = (d) => {
  return {
    type: SET_DOMAIN,
    data: d,
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...initialState,
        loggingIn: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        user: action.user,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        loggingIn: false,
        error: action.error,
      };
    case LOGOUT:
      return {
        ...initialState,
      };

    case AUTH_MISSING_ERROR:
      return {
        ...initialState,
        missingError: "no access token found",
      };

    case FETCH_USER_DOMAIN:
      return {
        ...state,
        fetchingDomains: true,
        domains: [],
        domainLoadError: null,
      };
    case FETCH_USER_DOMAIN_SUCCESS:
      return {
        ...state,
        fetchingDomains: false,
        domains: action.data,
        domainLoadError: null,
      };
    case FETCH_USER_DOMAIN_FAILED:
      return {
        ...state,
        fetchingDomains: false,
        domains: [],
        domainLoadError: action.error,
      };

    case SET_DOMAIN:
      return {
        ...state,
        selectedDomain: action.data,
      };
    default:
      return state;
  }
};
