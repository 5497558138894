import { combineReducers } from "redux";
import auth from "reducers/auth";
import audit from "reducers/audit";
import queue from "reducers/queue";
import dmarcReport from "reducers/dmarcReport";
import dashboard from "reducers/dashboard";
import quarantine from "reducers/quarantine";
import consentManagement from "reducers/consentManagement";
import consentMirroring from "reducers/consentMirroring";
import unsubscribeManager from "reducers/unsubscribeManager";
import activeSenders from "reducers/activeSenders";
import systemSettings from "reducers/systemSettings";
import unsubscribePreference from "reducers/unsubscribePreference";

export default combineReducers({
  auth,
  audit,
  queue,
  dmarcReport,
  quarantine,
  consentManagement,
  unsubscribeManager,
  activeSenders,
  systemSettings,
  dashboard,
  consentMirroring,
  unsubscribePreference
});
