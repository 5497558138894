import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
// Logger with default options
// import logger from "redux-logger";

import rootReducer from "./reducers/rootReducer";

import { persistStore, persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import storageSession from 'redux-persist/lib/storage/session'

const persistConfig = {
  key: "root",
  blacklist: ["audit", "quarantine", "consentManagement"],
  storage: storageSession,
};

const composeEnhancers = composeWithDevTools({
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
export default function configureStore(initialState) {
  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(
      applyMiddleware()
      // other store enhancers if any
    )
  );
  let persistor = persistStore(store);
  return { store, persistor };
}
