import axios from "axios";

import { authIsMissing } from "./auth";

export const FETCH_USER_SETTINGS = "SYSTEM_SETTINGS/FETCH_USER_SETTINGS";
export const FETCH_USER_SETTINGS_SUCCESS = "SYSTEM_SETTINGS/FETCH_USER_SETTINGS_SUCCESS";
export const FETCH_USER_SETTINGS_FAILED = "SYSTEM_SETTINGS/FETCH_USER_SETTINGS_FAILED";

export const FETCH_SENDING_DOMAINS = "SYSTEM_SETTINGS/FETCH_SENDING_DOMAINS";
export const FETCH_SENDING_DOMAINS_SUCCESS = "SYSTEM_SETTINGS/FETCH_SENDING_DOMAINS_SUCCESS";
export const FETCH_SENDING_DOMAINS_FAILED = "SYSTEM_SETTINGS/FETCH_SENDING_DOMAINS_FAILED";

export const FETCH_QUARANTINE_SETTINGS = "SYSTEM_SETTINGS/FETCH_QUARANTINE_SETTINGS";
export const FETCH_QUARANTINE_SETTINGS_SUCCESS = "SYSTEM_SETTINGS/FETCH_QUARANTINE_SETTINGS_SUCCESS";
export const FETCH_QUARANTINE_SETTINGS_FAILED = "SYSTEM_SETTINGS/FETCH_QUARANTINE_SETTINGS_FAILED";

export const SAVE_QUARANTINE_SETTINGS = "SYSTEM_SETTINGS/SAVE_QUARANTINE_SETTINGS";
export const SAVE_QUARANTINE_SETTINGS_SUCCESS = "SYSTEM_SETTINGS/SAVE_QUARANTINE_SETTINGS_SUCCESS";
export const SAVE_QUARANTINE_SETTINGS_FAILED = "SYSTEM_SETTINGS/SAVE_QUARANTINE_SETTINGS_FAILED";

export const DELETE_RECORD = "SYSTEM_SETTINGS/DELETE_RECORD";
export const DELETE_RECORD_SUCCESS = "SYSTEM_SETTINGS/DELETE_RECORD_SUCCESS";
export const DELETE_RECORD_FAILED = "SYSTEM_SETTINGS/DELETE_RECORD_FAILED";
export const CLEAR_DELETE_RECORD_ERROR = "SYSTEM_SETTINGS/CLEAR_DELETE_RECORD_ERROR";
export const CLOSE_DELETE_CONFIRM_MODAL = "SYSTEM_SETTINGS/CLOSE_DELETE_CONFIRM_MODAL";

export const ADD_DOMAIN_RECORD = "SYSTEM_SETTINGS/ADD_DOMAIN_RECORD";
export const ADD_DOMAIN_RECORD_SUCCESS = "SYSTEM_SETTINGS/ADD_DOMAIN_RECORD_SUCCESS";
export const ADD_DOMAIN_RECORD_FAILED = "SYSTEM_SETTINGS/ADD_DOMAIN_RECORD_FAILED";
export const CLOSE_NEW_DOMAIN_SAVE_MODAL = "SYSTEM_SETTINGS/CLOSE_NEW_DOMAIN_SAVE_MODAL";

export const SAVE_USER_SETTINGS = "SYSTEM_SETTINGS/SAVE_USER_SETTINGS";
export const SAVE_USER_SETTINGS_SUCCESS = "SYSTEM_SETTINGS/SAVE_USER_SETTINGS_SUCCESS";
export const SAVE_USER_SETTINGS_FAILED = "SYSTEM_SETTINGS/SAVE_USER_SETTINGS_FAILED";

export const SAVE_DOMAIN_SETTINGS = "SYSTEM_SETTINGS/SAVE_DOMAIN_SETTINGS";
export const SAVE_DOMAIN_SETTINGS_SUCCESS = "SYSTEM_SETTINGS/SAVE_DOMAIN_SETTINGS_SUCCESS";
export const SAVE_DOMAIN_SETTINGS_FAILED = "SYSTEM_SETTINGS/SAVE_DOMAIN_SETTINGS_FAILED";

export const CLEAR_ERRORS = "SYSTEM_SETTINGS/CLEAR_ERRORS";


const initialState = {
  loading: false,
  sendingDomains: [],
  sendingDomainFetchError: null,
  
  showDeleteConfirmModal: false,
  deletingRecord: false,
  deleteRecordError: null,
    
  userSettingsLoadError: null,
  loadingUserSettings: false,
  userSettings: {},
  userSettingsSaveError: null,
  savingUserSettings: false,
  
  savingNewDomain: false,
  saveNewDomainError: null,
  savingDomainSettings: false,
  domainSettingsSaveError: null,

  loadingQuarantineSettings: false,
  quarantineSettings: [],
  quarantineSettingsLoadError: null,
  savingQuarantineSettings: false,
  quarantineSettingsSaveError: null,

};

export const fetchUserSettings = (dispatch) => () => {
  dispatch({
    type: FETCH_USER_SETTINGS,
  });
  const accessToken = sessionStorage.getItem("accessToken");

  if (
    accessToken === null ||
    accessToken === "" ||
    accessToken === "undefined"
  ) {
    sessionStorage.removeItem("accessToken");
    return dispatch(authIsMissing());
  }

  return axios
    .get(`${process.env.REACT_APP_API_HOST}/user/settings`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then((res) => {
      return dispatch({
        type: FETCH_USER_SETTINGS_SUCCESS,
        data: res.data.data,
      });
    })
    .catch((err) => {
      if (
        err !== undefined &&
        err.response !== undefined &&
        err.response.status === 401
      ) {
        // lets authenticate again
        sessionStorage.removeItem("accessToken");
        return dispatch(authIsMissing());
      }
      return dispatch({
        type: FETCH_USER_SETTINGS_FAILED,
        error: err,
      });
    });
};

export const saveUserSettings = (dispatch) => (data) => {
  dispatch({
    type: SAVE_USER_SETTINGS,
  });

  const accessToken = sessionStorage.getItem("accessToken");

  if (
    accessToken === null ||
    accessToken === "" ||
    accessToken === "undefined"
  ) {
    sessionStorage.removeItem("accessToken");
    return dispatch(authIsMissing());
  }

  return axios({
    method: "post",
    url: `${process.env.REACT_APP_API_HOST}/user/settings`,
    data,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
    },
  })
    .then((res) => {
      return dispatch({
        type: SAVE_USER_SETTINGS_SUCCESS,
        data: res.data.data,
      });
    })
    .catch((err) => {
      if (
        err !== undefined &&
        err.response !== undefined &&
        err.response.status === 401
      ) {
        // lets authenticate again
        sessionStorage.removeItem("accessToken");
        return dispatch(authIsMissing());
      }
      return dispatch({
        type: SAVE_USER_SETTINGS_FAILED,
        error: err,
      });
    });
};

export const fetchQuarantineSettings = (dispatch) => (domainId) => {
  dispatch({
    type: FETCH_QUARANTINE_SETTINGS,
  });
  const accessToken = sessionStorage.getItem("accessToken");

  if (
    accessToken === null ||
    accessToken === "" ||
    accessToken === "undefined"
  ) {
    sessionStorage.removeItem("accessToken");
    return dispatch(authIsMissing());
  }

  return axios
    .get(`${process.env.REACT_APP_API_HOST}/quarantine/settings?domain_id=${domainId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then((res) => {
      return dispatch({
        type: FETCH_QUARANTINE_SETTINGS_SUCCESS,
        data: res.data.data,
      });
    })
    .catch((err) => {
      if (
        err !== undefined &&
        err.response !== undefined &&
        err.response.status === 401
      ) {
        // lets authenticate again
        sessionStorage.removeItem("accessToken");
        return dispatch(authIsMissing());
      }
      return dispatch({
        type: FETCH_QUARANTINE_SETTINGS_FAILED,
        error: err,
      });
    });
};

export const saveQuarantineSettings = (dispatch) => (domainId, data) => {
  dispatch({
    type: SAVE_QUARANTINE_SETTINGS,
  });

  const accessToken = sessionStorage.getItem("accessToken");

  if (
    accessToken === null ||
    accessToken === "" ||
    accessToken === "undefined"
  ) {
    sessionStorage.removeItem("accessToken");
    return dispatch(authIsMissing());
  }

  return axios({
    method: "post",
    url: `${process.env.REACT_APP_API_HOST}/quarantine/settings?domain_id=${domainId}`,
    data,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
    },
  })
    .then((res) => {
      dispatch({
        type: FETCH_QUARANTINE_SETTINGS_SUCCESS,
        data: res.data.data,
      });

      return dispatch({
        type: SAVE_QUARANTINE_SETTINGS_SUCCESS,
      });
    })
    .catch((err) => {
      if (
        err !== undefined &&
        err.response !== undefined &&
        err.response.status === 401
      ) {
        // lets authenticate again
        sessionStorage.removeItem("accessToken");
        return dispatch(authIsMissing());
      }
      return dispatch({
        type: SAVE_QUARANTINE_SETTINGS_FAILED,
        error: err,
      });
    });
};

export const saveNewDomain = (dispatch) => (data) => {
  dispatch({
    type: ADD_DOMAIN_RECORD,
  });

  const accessToken = sessionStorage.getItem("accessToken");

  if (
    accessToken === null ||
    accessToken === "" ||
    accessToken === "undefined"
  ) {
    sessionStorage.removeItem("accessToken");
    return dispatch(authIsMissing());
  }

  return axios({
    method: "post",
    url: `${process.env.REACT_APP_API_HOST}/domain/create`,
    data,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
    },
  })
    .then((res) => {
      return dispatch({
        type: ADD_DOMAIN_RECORD_SUCCESS,
      });
    })
    .catch((err) => {
      if (
        err !== undefined &&
        err.response !== undefined &&
        err.response.status === 401
      ) {
        // lets authenticate again
        sessionStorage.removeItem("accessToken");
        return dispatch(authIsMissing());
      }
      return dispatch({
        type: ADD_DOMAIN_RECORD_FAILED,
        error: err,
      });
    });
};

export const deleteSenderDomain = (dispatch) => (domainID) => {
  dispatch({
    type: DELETE_RECORD,
  });
  const accessToken = sessionStorage.getItem("accessToken");
  if (
    accessToken === null ||
    accessToken === "" ||
    accessToken === "undefined"
  ) {
    sessionStorage.removeItem("accessToken");
    return dispatch(authIsMissing());
  }

  let bodyFormData = new FormData();
  bodyFormData.set("id", domainID);

  return axios({
    method: "post",
    url: `${process.env.REACT_APP_API_HOST}/domain/remove`,
    data: bodyFormData,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
    },
  })
    .then((res) => {
      return dispatch({
        type: DELETE_RECORD_SUCCESS,
      });
    })
    .catch((err) => {
      if (
        err !== undefined &&
        err.response !== undefined &&
        err.response.status === 401
      ) {
        // lets authenticate again
        sessionStorage.removeItem("accessToken");
        return dispatch(authIsMissing());
      }
      return dispatch({
        type: DELETE_RECORD_FAILED,
        error: err,
      });
    });
};

export const closeDeleteConfirmModal = () => {
  return {
    type: CLOSE_DELETE_CONFIRM_MODAL,
  };
};

export const clearDeleteRecordError = () => {
  return {
    type: CLEAR_DELETE_RECORD_ERROR,
  };
};

export const closeNewDomainSaveModal = () => {
  return {
    type: CLOSE_NEW_DOMAIN_SAVE_MODAL,
  };
};

export const saveDomainSettings = (dispatch) => (data) => {
  dispatch({
    type: SAVE_DOMAIN_SETTINGS,
  });

  const accessToken = sessionStorage.getItem("accessToken");

  if (
    accessToken === null ||
    accessToken === "" ||
    accessToken === "undefined"
  ) {
    sessionStorage.removeItem("accessToken");
    return dispatch(authIsMissing());
  }

  return axios({
    method: "post",
    url: `${process.env.REACT_APP_API_HOST}/domain/settings`,
    data,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
    },
  })
    .then((res) => {
      return dispatch({
        type: SAVE_DOMAIN_SETTINGS_SUCCESS,
      });
    })
    .catch((err) => {
      if (
        err !== undefined &&
        err.response !== undefined &&
        err.response.status === 401
      ) {
        // lets authenticate again
        sessionStorage.removeItem("accessToken");
        return dispatch(authIsMissing());
      }
      return dispatch({
        type: SAVE_DOMAIN_SETTINGS_FAILED,
        error: err,
      });
    });
};


export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};


export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_ERRORS:
      return {
        ...state,
        sendingDomainFetchError: null,
        userSettingsLoadError: null,
        deleteRecordError: null,
        userSettingsSaveError: null,
        saveNewDomainError: null,
        domainSettingsSaveError: null,
        quarantineSettingsLoadError: null,
        quarantineSettingsSaveError: null,
      }; 
    case FETCH_SENDING_DOMAINS:
      return {
        ...state,
        fetching: true,
        sendingDomains: [],
        sendingDomainFetchError: null,
      };
    case FETCH_SENDING_DOMAINS_SUCCESS:
      return {
        ...state,
        fetching: false,
        sendingDomains: action.data,
        sendingDomainFetchError: null,
      };
    case FETCH_SENDING_DOMAINS_FAILED:
      return {
        ...state,
        fetching: false,
        sendingDomains: [],
        sendingDomainFetchError: action.error,
      };

    case FETCH_USER_SETTINGS:
      return {
        ...state,
        loadingUserSettings: true,
        userSettings: {},
        userSettingsLoadError: null,
      };
    case FETCH_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        loadingUserSettings: false,
        userSettings: action.data,
      };
    case FETCH_USER_SETTINGS_FAILED:
      return {
        ...state,
        loadingUserSettings: false,
        userSettings: {},
        userSettingsLoadError: action.error,
      };

    case CLOSE_DELETE_CONFIRM_MODAL:
      return {
        ...state,
        showDeleteConfirmModal: false,
      };

    case DELETE_RECORD:
      return {
        ...state,
        deletingRecord: true,
        showDeleteConfirmModal: true,
      };

    case DELETE_RECORD_SUCCESS:
      return {
        ...state,
        deletingRecord: false,
        deleteRecordError: null,
      };

    case DELETE_RECORD_FAILED:
      return {
        ...state,
        deletingRecord: false,
        deleteRecordError: action.error,
      };

    case CLEAR_DELETE_RECORD_ERROR:
      return {
        ...state,
        deleteRecordError: null,
      };

    case SAVE_USER_SETTINGS:
      return {
        ...state,
        savingUserSettings: true,
        userSettingsSaveError: null,
      };

    case SAVE_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        savingUserSettings: false,
        userSettingsSaveError: null,
        userSettings: action.data,
      };

    case SAVE_USER_SETTINGS_FAILED:
      return {
        ...state,
        savingUserSettings: false,
        userSettingsSaveError: action.error,
      };

    case ADD_DOMAIN_RECORD:
      return {
        ...state,
        savingNewDomain: true,
        showNewDomainSaveModal: true,
        saveNewDomainError: null,
      };

    case ADD_DOMAIN_RECORD_SUCCESS:
      return {
        ...state,
        savingNewDomain: false,
        showNewDomainSaveModal: true,
        saveNewDomainError: null,
      };

    case ADD_DOMAIN_RECORD_FAILED:
      return {
        ...state,
        savingNewDomain: false,
        showNewDomainSaveModal: false,
        saveNewDomainError: action.error,
      };

    case CLOSE_NEW_DOMAIN_SAVE_MODAL:
      return {
        ...state,
        showNewDomainSaveModal: false,
      };

    case SAVE_DOMAIN_SETTINGS:
      return {
        ...state,
        savingDomainSettings: true,
        domainSettingsSaveError: null,
      };

    case SAVE_DOMAIN_SETTINGS_SUCCESS:
      return {
        ...state,
        savingDomainSettings: false,
        domainSettingsSaveError: null,
      };

    case SAVE_DOMAIN_SETTINGS_FAILED:
      return {
        ...state,
        savingDomainSettings: false,
        domainSettingsSaveError: action.error,
      };


    case FETCH_QUARANTINE_SETTINGS:
      return {
        ...state,
        loadingQuarantineSettings: true,
        quarantineSettings: [],
        quarantineSettingsLoadError: null,
      };
    case FETCH_QUARANTINE_SETTINGS_SUCCESS:
      return {
        ...state,
        loadingQuarantineSettings: false,
        quarantineSettings: action.data,
        quarantineSettingsLoadError: null,
      };
    case FETCH_QUARANTINE_SETTINGS_FAILED:
      return {
        ...state,
        loadingQuarantineSettings: false,
        quarantineSettings: [],
        quarantineSettingsLoadError: action.error,
      };

    case SAVE_QUARANTINE_SETTINGS:
      return {
        ...state,
        savingQuarantineSettings: true,
        quarantineSettingsSaveError: null,
      };

    case SAVE_QUARANTINE_SETTINGS_SUCCESS:
      return {
        ...state,
        savingQuarantineSettings: false,
        quarantineSettingsSaveError: null,
      };

    case SAVE_QUARANTINE_SETTINGS_FAILED:
      return {
        ...state,
        savingQuarantineSettings: false,
        quarantineSettingsSaveError: action.error,
      };

    default:
      return state;
  }
};
