import axios from "axios";

import { authIsMissing } from "./auth";

export const FETCH_DATA = "UNSUBSCRIBE_PREFERENCE/FETCH_DATA";
export const FETCH_DATA_SUCCESS = "UNSUBSCRIBE_PREFERENCE/FETCH_DATA_SUCCESS";
export const FETCH_DATA_FAILED = "UNSUBSCRIBE_PREFERENCE/FETCH_DATA_FAILED";

export const SAVE_ENTRY = "UNSUBSCRIBE_PREFERENCE/SAVE_ENTRY";
export const SAVE_ENTRY_FAILED = "UNSUBSCRIBE_PREFERENCE/SAVE_ENTRY_FAILED";
export const SAVE_ENTRY_SUCCESS = "UNSUBSCRIBE_PREFERENCE/SAVE_ENTRY_FAILED_SUCCESS";

export const CLOSE_SAVE_MODAL = "UNSUBSCRIBE_PREFERENCE/CLOSE_SAVE_MODAL";
export const CLOSE_DELETE_CONFIRM_MODAL = "UNSUBSCRIBE_PREFERENCE/CLOSE_DELETE_CONFIRM_MODAL";
export const CLEAR_SAVE_ERROR = "UNSUBSCRIBE_PREFERENCE/CLEAR_SAVE_ERROR";

export const DELETE_RECORD = "UNSUBSCRIBE_PREFERENCE/DELETE_RECORD";

export const DELETE_RECORD_SUCCESS = "UNSUBSCRIBE_PREFERENCE/DELETE_RECORD_SUCCESS";
export const DELETE_RECORD_FAILED = "UNSUBSCRIBE_PREFERENCE/DELETE_RECORD_FAILED";
export const CLEAR_DELETE_ERROR = "UNSUBSCRIBE_PREFERENCE/CLEAR_DELETE_ERROR";

export const CLEAR_ERRORS = "UNSUBSCRIBE_PREFERENCE/CLEAR_ERRORS";

const initialState = {
  loading: false,
  data: [],
  error: null,
  pages: -1,
  saveError: null,
  saving: false,
  deleting: false,
  deleteError: null,
};

let abortController; // Module scoped abort controller
export const fetchData = (dispatch) => (
  page,
  pageSize,
  domain
) => {
  dispatch({
    type: FETCH_DATA,
  });

  const accessToken = sessionStorage.getItem("accessToken");
  if (
    accessToken === null ||
    accessToken === "" ||
    accessToken === "undefined"
  ) {
    sessionStorage.removeItem("accessToken");
    return dispatch(authIsMissing());
  }
  let params = {
    page,
    pageSize,
  };

  if (!!domain && domain !== -1) {
    params["domainId"] = domain;
  }

  if (abortController) {
    abortController.abort(); // Tell the browser to abort request
  }
  abortController = typeof 'AbortController' !== 'undefined' && new AbortController();

  return axios
    .get(`${process.env.REACT_APP_API_HOST}/unsubscribed/preference`, {
      params,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      signal: abortController.signal,
    })
    .then((res) => {
      const auditDate = res.data.data.results.map((row, idx) => {
        return { ...row, rowIndex: idx };
      });
      return dispatch({
        type: FETCH_DATA_SUCCESS,
        data: auditDate,
        pages: res.data.data.pageCount,
        pageSize: res.data.data.pageSize,
      });
    })
    .catch((err) => {
      if (
        err !== undefined &&
        err.response !== undefined &&
        err.response.status === 401
      ) {
        // lets authenticate again
        sessionStorage.removeItem("accessToken");
        return dispatch(authIsMissing());
      }
      return dispatch({
        type: FETCH_DATA_FAILED,
        error: err,
      });
    });
};

export const saveEntry = (dispatch) => (data) => {
  dispatch({
    type: SAVE_ENTRY,
  });

  const accessToken = sessionStorage.getItem("accessToken");

  if (
    accessToken === null ||
    accessToken === "" ||
    accessToken === "undefined"
  ) {
    sessionStorage.removeItem("accessToken");
    return dispatch(authIsMissing());
  }

  return axios({
    method: "post",
    url: `${process.env.REACT_APP_API_HOST}/unsubscribed/preference-create`,
    data,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
    },
  })
    .then((res) => {
      return dispatch({
        type: SAVE_ENTRY_SUCCESS,
      });
    })
    .catch((err) => {
      if (
        err !== undefined &&
        err.response !== undefined &&
        err.response.status === 401
      ) {
        // lets authenticate again
        sessionStorage.removeItem("accessToken");
        return dispatch(authIsMissing());
      }
      return dispatch({
        type: SAVE_ENTRY_FAILED,
        error: err,
      });
    });
};

export const deleteEntry = (dispatch) => (dataID) => {
  dispatch({
    type: DELETE_RECORD,
  });

  const accessToken = sessionStorage.getItem("accessToken");
  if (
    accessToken === null ||
    accessToken === "" ||
    accessToken === "undefined"
  ) {
    sessionStorage.removeItem("accessToken");
    return dispatch(authIsMissing());
  }

  let bodyFormData = new FormData();
  bodyFormData.set("id", dataID);

  return axios({
    method: "post",
    url: `${process.env.REACT_APP_API_HOST}/unsubscribed/preference-remove`,
    data: bodyFormData,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
    },
  })
    .then((res) => {
      return dispatch({
        type: DELETE_RECORD_SUCCESS,
      });
    })
    .catch((err) => {
      if (
        err !== undefined &&
        err.response !== undefined &&
        err.response.status === 401
      ) {
        // lets authenticate again
        sessionStorage.removeItem("accessToken");
        return dispatch(authIsMissing());
      }
      return dispatch({
        type: DELETE_RECORD_FAILED,
        error: err,
      });
    });
};


export const clearDeleteError = () => {
  return {
    type: CLEAR_DELETE_ERROR,
  };
};

export const clearSaveError = () => {
  return {
    type: CLEAR_SAVE_ERROR,
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        saveError: null,
        deleteError: null,
      };
    case FETCH_DATA:
      return {
        ...state,
        loading: true,
        data: [],
        error: null,
      };
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        pages: action.pages,
        error: null,
      };
    case FETCH_DATA_FAILED:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.error,
      };

    case SAVE_ENTRY:
      return {
        ...state,
        saving: true,
        saveError: null,
      };

    case SAVE_ENTRY_SUCCESS:
      return {
        ...state,
        saving: false,
        saveError: null,
      };

    case SAVE_ENTRY_FAILED:
      return {
        ...state,
        saving: false,
        saveError: action.error,
      };

    case CLEAR_SAVE_ERROR:
      return {
        ...state,
        saveError: null,
      };

    case DELETE_RECORD:
      return {
        ...state,
        deleting: true,
      };

    case DELETE_RECORD_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleteError: null,
      };

    case DELETE_RECORD_FAILED:
      return {
        ...state,
        deleting: false,
        deleteError: action.error,
      };

    case CLEAR_DELETE_ERROR:
      return {
        ...state,
        deleteError: null,
      };

    default:
      return state;
  }
};
