import axios from "axios";
import FileSaver from 'file-saver';

import { authIsMissing } from "./auth";
export const FETCH_QUARANTINE_DATA = "QUARANTINE/FETCH_QUARANTINE_DATA";
export const FETCH_QUARANTINE_SUCCESS = "QUARANTINE/FETCH_QUARANTINE_SUCCESS";
export const FETCH_QUARANTINE_FAILED = "QUARANTINE/FETCH_QUARANTINE_FAILED";

export const FETCH_QUARANTINE_XLS = "QUARANTINE/FETCH_QUARANTINE_XLS";
export const FETCH_QUARANTINE_XLS_SUCCESS = "QUARANTINE/FETCH_QUARANTINE_XLS_SUCCESS";
export const FETCH_QUARANTINE_XLS_FAILED = "QUARANTINE/FETCH_QUARANTINE_XLS_FAILED";

export const SAVE_CONSENT_ENTRY = "QUARANTINE/SAVE_CONSENT_ENTRY";
export const SAVE_CONSENT_ENTRY_FAILED = "QUARANTINE/SAVE_CONSENT_ENTRY_FAILED";
export const CLOSE_SAVE_CONSENT_MODAL = "QUARANTINE/CLOSE_SAVE_CONSENT_MODAL";

export const CLOSE_DELETE_CONFIRM_MODAL =
  "QUARANTINE/CLOSE_DELETE_CONFIRM_MODAL";

export const CLEAR_CONSENT_SAVE_ERROR = "QUARANTINE/CLEAR_CONSENT_SAVE_ERROR";
export const SAVE_CONSENT_ENTRY_SUCCESS =
  "QUARANTINE/SAVE_CONSENT_ENTRY_FAILED_SUCCESS";

export const DELETE_RECORD = "QUARANTINE/DELETE_RECORD";

export const DELETE_RECORD_SUCCESS = "QUARANTINE/DELETE_RECORD_SUCCESS";
export const DELETE_RECORD_FAILED = "QUARANTINE/DELETE_RECORD_FAILED";
export const CLEAR_DELETE_RECORD_ERROR = "QUARANTINE/CLEAR_DELETE_RECORD_ERROR";
export const CLEAR_ERRORS = "QUARANTINE/CLEAR_ERRORS";

const initialState = {
  loading: false,
  data: [],
  error: null,
  pages: -1,
  consentSaveError: null,
  savingConsentEntry: false,
  showConsentSaveModal: false,
  showDeleteConfirmModal: false,
  deletingRecord: false,
  deleteRecordError: null,
  fetchingXls: false,
};

let abortController; // Module scoped abort controller
export const fetchData = (dispatch) => (page, pageSize, domain) => {
  dispatch({
    type: FETCH_QUARANTINE_DATA,
  });
  const accessToken = sessionStorage.getItem("accessToken");

  if (
    accessToken === null ||
    accessToken === "" ||
    accessToken === "undefined"
  ) {
    sessionStorage.removeItem("accessToken");
    return dispatch(authIsMissing());
  }

  const params = {
    page,
    pageSize,
  };

  // add domain if not default
  if (!!domain && domain !== -1) {
    params["domainId"] = domain;
  }

  if (abortController) {
    abortController.abort(); // Tell the browser to abort request
  }
  abortController = typeof 'AbortController' !== 'undefined' && new AbortController();

  return axios
    .get(`${process.env.REACT_APP_API_HOST}/quarantine`, {
      params,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      signal: abortController.signal,
    })
    .then((res) => {
      // add index to each row
      const quarantineData = res.data.data.results.map((row, idx) => {
        return { ...row, rowIndex: idx+1 };
      });
      return dispatch({
        type: FETCH_QUARANTINE_SUCCESS,
        data: quarantineData,
        pages: res.data.data.pageCount,
        pageSize: res.data.data.pageSize,
      });
    })
    .catch((err) => {
      if (
        err !== undefined &&
        err.response !== undefined &&
        err.response.status === 401
      ) {
        // lets authenticate again
        sessionStorage.removeItem("accessToken");
        return dispatch(authIsMissing());
      }
      return dispatch({
        type: FETCH_QUARANTINE_FAILED,
        error: err,
      });
    });
};


export const fetchXls = (dispatch) => (domain) => {
  dispatch({
    type: FETCH_QUARANTINE_XLS,
  });
  const accessToken = sessionStorage.getItem("accessToken");

  if (
    accessToken === null ||
    accessToken === "" ||
    accessToken === "undefined"
  ) {
    sessionStorage.removeItem("accessToken");
    return dispatch(authIsMissing());
  }

  const params = {
    export: 1
  };

  // add domain if not default
  if (!!domain && domain !== -1) {
    params["domainId"] = domain;
  }

  return axios
    .get(`${process.env.REACT_APP_API_HOST}/quarantine`, {
      params,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      responseType: 'blob',
    })
    .then((res) => {
      FileSaver.saveAs(res.data, "quarantine.csv");

      return dispatch({
        type: FETCH_QUARANTINE_XLS_SUCCESS,
      });
    })
    .catch((err) => {
      if (
        err !== undefined &&
        err.response !== undefined &&
        err.response.status === 401
      ) {
        // lets authenticate again
        sessionStorage.removeItem("accessToken");
        return dispatch(authIsMissing());
      }
      return dispatch({
        type: FETCH_QUARANTINE_XLS_FAILED,
        error: err,
      });
    });
};


export const saveConsentEntry = (dispatch) => (data) => {
  dispatch({
    type: SAVE_CONSENT_ENTRY,
  });

  const accessToken = sessionStorage.getItem("accessToken");

  if (
    accessToken === null ||
    accessToken === "" ||
    accessToken === "undefined"
  ) {
    sessionStorage.removeItem("accessToken");
    return dispatch(authIsMissing());
  }

  return axios({
    method: "post",
    url: `${process.env.REACT_APP_API_HOST}/quarantine/consent`,
    data,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
    },
  })
    .then((res) => {
      return dispatch({
        type: SAVE_CONSENT_ENTRY_SUCCESS,
      });
    })
    .catch((err) => {
      if (
        err !== undefined &&
        err.response !== undefined &&
        err.response.status === 401
      ) {
        // lets authenticate again
        sessionStorage.removeItem("accessToken");
        return dispatch(authIsMissing());
      }
      return dispatch({
        type: SAVE_CONSENT_ENTRY_FAILED,
        error: err,
      });
    });
};

export const deleteQuarantineEntry = (dispatch) => (data) => {
  dispatch({
    type: DELETE_RECORD,
  });
  const accessToken = sessionStorage.getItem("accessToken");
  if (
    accessToken === null ||
    accessToken === "" ||
    accessToken === "undefined"
  ) {
    sessionStorage.removeItem("accessToken");
    return dispatch(authIsMissing());
  }

  return axios({
    method: "post",
    url: `${process.env.REACT_APP_API_HOST}/quarantine/remove`,
    data,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
    },
  })
    .then((res) => {
      return dispatch({
        type: DELETE_RECORD_SUCCESS,
      });
    })
    .catch((err) => {
      if (
        err !== undefined &&
        err.response !== undefined &&
        err.response.status === 401
      ) {
        // lets authenticate again
        sessionStorage.removeItem("accessToken");
        return dispatch(authIsMissing());
      }
      return dispatch({
        type: DELETE_RECORD_FAILED,
        error: err,
      });
    });
};

export const closeSaveConsentModal = () => {
  return {
    type: CLOSE_SAVE_CONSENT_MODAL,
  };
};

export const closeDeleteConfirmModal = () => {
  return {
    type: CLOSE_DELETE_CONFIRM_MODAL,
  };
};

export const clearDeleteRecordError = () => {
  return {
    type: CLEAR_DELETE_RECORD_ERROR,
  };
};

export const clearConsentSaveError = () => {
  return {
    type: CLEAR_CONSENT_SAVE_ERROR,
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        consentSaveError: null,
        deleteRecordError: null,
      };  
    case FETCH_QUARANTINE_DATA:
      return {
        ...state,
        loading: true,
        data: [],
        error: null,
      };
    case FETCH_QUARANTINE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        pages: action.pages,
        error: null,
      };
    case FETCH_QUARANTINE_FAILED:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.error,
      };

    case FETCH_QUARANTINE_XLS:
      return {
        ...state,
        fetchingXls: true,
      };
    case FETCH_QUARANTINE_XLS_SUCCESS:
      return {
        ...state,
        fetchingXls: false,
      };
    case FETCH_QUARANTINE_XLS_FAILED:
      return {
        ...state,
        fetchingXls: false,
      };

    case SAVE_CONSENT_ENTRY:
      return {
        ...state,
        savingConsentEntry: true,
        showConsentSaveModal: true,
        consentSaveError: null,
      };

    case SAVE_CONSENT_ENTRY_SUCCESS:
      return {
        ...state,
        savingConsentEntry: false,
        consentSaveError: null,
      };

    case SAVE_CONSENT_ENTRY_FAILED:
      return {
        ...state,
        savingConsentEntry: false,
        showConsentSaveModal: false,
        consentSaveError: action.error,
      };

    case CLEAR_CONSENT_SAVE_ERROR:
      return {
        ...state,
        consentSaveError: null,
      };

    case CLOSE_DELETE_CONFIRM_MODAL:
      return {
        ...state,
        showDeleteConfirmModal: false,
      };

    case CLOSE_SAVE_CONSENT_MODAL:
      return {
        ...state,
        showConsentSaveModal: false,
      };

    case DELETE_RECORD:
      return {
        ...state,
        deletingRecord: true,
        showDeleteConfirmModal: true,
      };

    case DELETE_RECORD_SUCCESS:
      return {
        ...state,
        deletingRecord: false,
        deleteRecordError: null,
      };

    case DELETE_RECORD_FAILED:
      return {
        ...state,
        deletingRecord: false,
        deleteRecordError: action.error,
      };

    case CLEAR_DELETE_RECORD_ERROR:
      return {
        ...state,
        deleteRecordError: null,
      };

    default:
      return state;
  }
};
