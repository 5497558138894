import axios from "axios";
import FileSaver from 'file-saver';

import { authIsMissing } from "./auth";

export const FETCH_CONSENT_MANAGEMENT_DATA = "CONSENT_MANAGEMENT/FETCH_CONSENT_MANAGEMENT_DATA";
export const FETCH_CONSENT_MANAGEMENT_DATA_SUCCESS = "CONSENT_MANAGEMENT/FETCH_CONSENT_MANAGEMENT_DATA_SUCCESS";
export const FETCH_CONSENT_MANAGEMENT_DATA_FAILED = "CONSENT_MANAGEMENT/FETCH_CONSENT_MANAGEMENT_DATA_FAILED";

export const FETCH_CONSENT_MANAGEMENT_XLS = "CONSENT_MANAGEMENT/FETCH_CONSENT_MANAGEMENT_XLS";
export const FETCH_CONSENT_MANAGEMENT_XLS_SUCCESS = "CONSENT_MANAGEMENT/FETCH_CONSENT_MANAGEMENT_XLS_SUCCESS";
export const FETCH_CONSENT_MANAGEMENT_XLS_FAILED = "CONSENT_MANAGEMENT/FETCH_CONSENT_MANAGEMENT_XLS_FAILED";

export const FETCH_CONSENT_DOCUMENT = "CONSENT_MANAGEMENT/FETCH_CONSENT_DOCUMENT";
export const FETCH_CONSENT_DOCUMENT_SUCCESS = "CONSENT_MANAGEMENT/FETCH_CONSENT_DOCUMENT_SUCCESS";
export const FETCH_CONSENT_DOCUMENT_FAILED = "CONSENT_MANAGEMENT/FETCH_CONSENT_DOCUMENT_FAILED";

export const FETCH_CONSENT_TYPES = "CONSENT_MANAGEMENT/FETCH_CONSENT_TYPES";
export const FETCH_CONSENT_TYPES_SUCCESS = "CONSENT_MANAGEMENT/FETCH_CONSENT_TYPES_SUCCESS";
export const FETCH_CONSENT_TYPES_FAILED = "CONSENT_MANAGEMENT/FETCH_CONSENT_TYPES_FAILED";

export const FETCH_CONSENT_STATS = "CONSENT_MANAGEMENT/FETCH_CONSENT_STATS";
export const FETCH_CONSENT_STATS_SUCCESS = "CONSENT_MANAGEMENT/FETCH_CONSENT_STATS_SUCCESS";
export const FETCH_CONSENT_STATS_FAILED = "CONSENT_MANAGEMENT/FETCH_CONSENT_STATS_FAILED";

export const SAVE_CONSENT_ENTRY = "CONSENT_MANAGEMENT/SAVE_CONSENT_ENTRY";
export const SAVE_CONSENT_ENTRY_FAILED = "CONSENT_MANAGEMENT/SAVE_CONSENT_ENTRY_FAILED";
export const SAVE_CONSENT_ENTRY_SUCCESS = "CONSENT_MANAGEMENT/SAVE_CONSENT_ENTRY_FAILED_SUCCESS";

export const SAVE_CONSENT_CSV = "CONSENT_MANAGEMENT/SAVE_CONSENT_CSV";
export const SAVE_CONSENT_CSV_FAILED = "CONSENT_MANAGEMENT/SAVE_CONSENT_CSV_FAILED";
export const SAVE_CONSENT_CSV_SUCCESS = "CONSENT_MANAGEMENT/SAVE_CONSENT_CSV_SUCCESS";

export const CHECK_CONSENT_ENTRY = "CONSENT_MANAGEMENT/CHECK_CONSENT_ENTRY";
export const CHECK_CONSENT_ENTRY_FAILED = "CONSENT_MANAGEMENT/CHECK_CONSENT_ENTRY_FAILED";
export const CHECK_CONSENT_ENTRY_SUCCESS = "CONSENT_MANAGEMENT/CHECK_CONSENT_ENTRY_FAILED_SUCCESS";

export const CLOSE_SAVE_CONSENT_MODAL = "CONSENT_MANAGEMENT/CLOSE_SAVE_CONSENT_MODAL";
export const CLOSE_DELETE_CONFIRM_MODAL = "CONSENT_MANAGEMENT/CLOSE_DELETE_CONFIRM_MODAL";
export const CLEAR_CONSENT_SAVE_ERROR = "CONSENT_MANAGEMENT/CLEAR_CONSENT_SAVE_ERROR";


export const DELETE_RECORD = "CONSENT_MANAGEMENT/DELETE_RECORD";

export const DELETE_RECORD_SUCCESS = "CONSENT_MANAGEMENT/DELETE_RECORD_SUCCESS";
export const DELETE_RECORD_FAILED = "CONSENT_MANAGEMENT/DELETE_RECORD_FAILED";
export const CLEAR_DELETE_RECORD_ERROR = "CONSENT_MANAGEMENT/CLEAR_DELETE_RECORD_ERROR";
export const CLEAR_ERRORS = "CONSENT_MANAGEMENT/CLEAR_ERRORS";

const initialState = {
  loading: false,
  data: [],
  error: null,
  fetchingConsentTypes: false,
  consentTypes: null,
  fetchConsentTypesError: null,
  pages: -1,
  consentSaveError: null,
  savingConsentEntry: false,
  showConsentSaveModal: false,
  showDeleteConfirmModal: false,
  deletingRecord: false,
  deleteRecordError: null,
  fetchingXls: false,
  checkConsentResult: null
};

let abortController; // Module scoped abort controller
export const fetchData = (dispatch) => (
  sender,
  recipient,
  page,
  pageSize,
  domain
) => {
  dispatch({
    type: FETCH_CONSENT_MANAGEMENT_DATA,
  });

  const accessToken = sessionStorage.getItem("accessToken");
  if (
    accessToken === null ||
    accessToken === "" ||
    accessToken === "undefined"
  ) {
    sessionStorage.removeItem("accessToken");
    return dispatch(authIsMissing());
  }
  let params = {
    sender,
    recipient,
    page,
    pageSize,
  };

  if (!!domain && domain !== -1) {
    params["domainId"] = domain;
  }

  if (abortController) {
    abortController.abort(); // Tell the browser to abort request
  }
  abortController = typeof 'AbortController' !== 'undefined' && new AbortController();

  return axios
    .get(`${process.env.REACT_APP_API_HOST}/consent`, {
      params,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      signal: abortController.signal,      
    })
    .then((res) => {
      const auditDate = res.data.data.results.map((row, idx) => {
        return { ...row, rowIndex: idx };
      });
      return dispatch({
        type: FETCH_CONSENT_MANAGEMENT_DATA_SUCCESS,
        data: auditDate,
        pages: res.data.data.pageCount,
        pageSize: res.data.data.pageSize,
      });
    })
    .catch((err) => {
      if (
        err !== undefined &&
        err.response !== undefined &&
        err.response.status === 401
      ) {
        // lets authenticate again
        sessionStorage.removeItem("accessToken");
        return dispatch(authIsMissing());
      }
      return dispatch({
        type: FETCH_CONSENT_MANAGEMENT_DATA_FAILED,
        error: err,
      });
    });
};

export const fetchXls = (dispatch) => (
  sender,
  recipient,
  domain
) => {
  dispatch({
    type: FETCH_CONSENT_MANAGEMENT_XLS,
  });

  const accessToken = sessionStorage.getItem("accessToken");
  if (
    accessToken === null ||
    accessToken === "" ||
    accessToken === "undefined"
  ) {
    sessionStorage.removeItem("accessToken");
    return dispatch(authIsMissing());
  }
  let params = {
    sender,
    recipient,
    export: 1
  };

  if (!!domain && domain !== -1) {
    params["domainId"] = domain;
  }

  return axios
    .get(`${process.env.REACT_APP_API_HOST}/consent`, {
      params,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      responseType: 'blob',
    })
    .then((res) => {

      FileSaver.saveAs(res.data, "consent.csv");

      return dispatch({
        type: FETCH_CONSENT_MANAGEMENT_XLS_SUCCESS,
      });
    })
    .catch((err) => {
      if (
        err !== undefined &&
        err.response !== undefined &&
        err.response.status === 401
      ) {
        // lets authenticate again
        sessionStorage.removeItem("accessToken");
        return dispatch(authIsMissing());
      }
      return dispatch({
        type: FETCH_CONSENT_MANAGEMENT_XLS_FAILED,
        error: err,
      });
    });
};

export const fetchConsentDocument = (dispatch) => (
  consentDocumentId,
  filename
) => {
  dispatch({
    type: FETCH_CONSENT_DOCUMENT,
  });

  const accessToken = sessionStorage.getItem("accessToken");
  if (
    accessToken === null ||
    accessToken === "" ||
    accessToken === "undefined"
  ) {
    sessionStorage.removeItem("accessToken");
    return dispatch(authIsMissing());
  }
  let params = {
    consentDocumentId
  };

  return axios
    .get(`${process.env.REACT_APP_API_HOST}/consent/document`, {
      params,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      responseType: 'blob',
    })
    .then((res) => {

      FileSaver.saveAs(res.data, filename);

      return dispatch({
        type: FETCH_CONSENT_DOCUMENT_SUCCESS,
      });
    })
    .catch((err) => {
      if (
        err !== undefined &&
        err.response !== undefined &&
        err.response.status === 401
      ) {
        // lets authenticate again
        sessionStorage.removeItem("accessToken");
        return dispatch(authIsMissing());
      }
      return dispatch({
        type: FETCH_CONSENT_DOCUMENT_FAILED,
        error: err,
      });
    });
};

export const fetchConsentTypes = (dispatch) => (sender) => {
  dispatch({
    type: FETCH_CONSENT_TYPES,
  });

  const accessToken = sessionStorage.getItem("accessToken");

  if (
    accessToken === null ||
    accessToken === "" ||
    accessToken === "undefined"
  ) {
    sessionStorage.removeItem("accessToken");
    return dispatch(authIsMissing());
  }

  let params = {
    sender,
  };

  return axios
    .get(`${process.env.REACT_APP_API_HOST}/consent/type`, {
      params,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then((res) => {
      return dispatch({
        type: FETCH_CONSENT_TYPES_SUCCESS,
        data: res.data.data.results,
      });
    })
    .catch((err) => {
      if (
        err !== undefined &&
        err.response !== undefined &&
        err.response.status === 401
      ) {
        // lets authenticate again
        sessionStorage.removeItem("accessToken");
        return dispatch(authIsMissing());
      }
      return dispatch({
        type: FETCH_CONSENT_TYPES_FAILED,
        error: err,
      });
    });
};

export const uploadConsentCSV = (dispatch) => (data) => {
  dispatch({
    type: SAVE_CONSENT_CSV,
  });

  const accessToken = sessionStorage.getItem("accessToken");

  if (
    accessToken === null ||
    accessToken === "" ||
    accessToken === "undefined"
  ) {
    sessionStorage.removeItem("accessToken");
    return dispatch(authIsMissing());
  }

  return axios({
    method: "post",
    url: `${process.env.REACT_APP_API_HOST}/consent/create-csv`,
    data,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
    },
  })
    .then((res) => {
      return dispatch({
        type: SAVE_CONSENT_CSV_SUCCESS,
      });
    })
    .catch((err) => {
      if (
        err !== undefined &&
        err.response !== undefined &&
        err.response.status === 401
      ) {
        // lets authenticate again
        sessionStorage.removeItem("accessToken");
        return dispatch(authIsMissing());
      }
      return dispatch({
        type: SAVE_CONSENT_CSV_FAILED,
        error: err,
      });
    });
};

export const saveConsentEntry = (dispatch) => (data) => {
  dispatch({
    type: SAVE_CONSENT_ENTRY,
  });

  const accessToken = sessionStorage.getItem("accessToken");

  if (
    accessToken === null ||
    accessToken === "" ||
    accessToken === "undefined"
  ) {
    sessionStorage.removeItem("accessToken");
    return dispatch(authIsMissing());
  }

  return axios({
    method: "post",
    url: `${process.env.REACT_APP_API_HOST}/consent/create`,
    data,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
    },
  })
    .then((res) => {
      return dispatch({
        type: SAVE_CONSENT_ENTRY_SUCCESS,
      });
    })
    .catch((err) => {
      if (
        err !== undefined &&
        err.response !== undefined &&
        err.response.status === 401
      ) {
        // lets authenticate again
        sessionStorage.removeItem("accessToken");
        return dispatch(authIsMissing());
      }
      return dispatch({
        type: SAVE_CONSENT_ENTRY_FAILED,
        error: err,
      });
    });
};

export const checkConsentEntry = (dispatch) => (data) => {
  dispatch({
    type: CHECK_CONSENT_ENTRY,
  });

  const accessToken = sessionStorage.getItem("accessToken");

  if (
    accessToken === null ||
    accessToken === "" ||
    accessToken === "undefined"
  ) {
    sessionStorage.removeItem("accessToken");
    return dispatch(authIsMissing());
  }

  return axios({
    method: "post",
    url: `${process.env.REACT_APP_API_HOST}/consent/check`,
    data,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
    },
  })
    .then((res) => {
      return dispatch({
        type: CHECK_CONSENT_ENTRY_SUCCESS,
        data: res.data.data,
      });
    })
    .catch((err) => {
      if (
        err !== undefined &&
        err.response !== undefined &&
        err.response.status === 401
      ) {
        // lets authenticate again
        sessionStorage.removeItem("accessToken");
        return dispatch(authIsMissing());
      }
      return dispatch({
        type: CHECK_CONSENT_ENTRY_FAILED,
        error: err,
      });
    });
};

export const deleteConsentEntry = (dispatch) => (dataID) => {
  dispatch({
    type: DELETE_RECORD,
  });

  const accessToken = sessionStorage.getItem("accessToken");
  if (
    accessToken === null ||
    accessToken === "" ||
    accessToken === "undefined"
  ) {
    sessionStorage.removeItem("accessToken");
    return dispatch(authIsMissing());
  }

  let bodyFormData = new FormData();
  bodyFormData.set("id", dataID);

  return axios({
    method: "post",
    url: `${process.env.REACT_APP_API_HOST}/consent/remove`,
    data: bodyFormData,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
    },
  })
    .then((res) => {
      return dispatch({
        type: DELETE_RECORD_SUCCESS,
      });
    })
    .catch((err) => {
      if (
        err !== undefined &&
        err.response !== undefined &&
        err.response.status === 401
      ) {
        // lets authenticate again
        sessionStorage.removeItem("accessToken");
        return dispatch(authIsMissing());
      }
      return dispatch({
        type: DELETE_RECORD_FAILED,
        error: err,
      });
    });
};

export const closeSaveConsentModal = () => {
  return {
    type: CLOSE_SAVE_CONSENT_MODAL,
  };
};

export const closeDeleteConfirmModal = () => {
  return {
    type: CLOSE_DELETE_CONFIRM_MODAL,
  };
};

export const clearDeleteRecordError = () => {
  return {
    type: CLEAR_DELETE_RECORD_ERROR,
  };
};

export const clearConsentSaveError = () => {
  return {
    type: CLEAR_CONSENT_SAVE_ERROR,
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        fetchConsentTypesError: null,
        consentSaveError: null,
        deleteRecordError: null,
      };     
    case FETCH_CONSENT_MANAGEMENT_DATA:
      return {
        ...state,
        loading: true,
        data: [],
        error: null,
      };
    case FETCH_CONSENT_MANAGEMENT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        pages: action.pages,
        error: null,
      };
    case FETCH_CONSENT_MANAGEMENT_DATA_FAILED:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.error,
      };

    case FETCH_CONSENT_MANAGEMENT_XLS:
      return {
        ...state,
        fetchingXls: true,
      };
    case FETCH_CONSENT_MANAGEMENT_XLS_SUCCESS:
      return {
        ...state,
        fetchingXls: false,
      };
    case FETCH_CONSENT_MANAGEMENT_XLS_FAILED:
      return {
        ...state,
        fetchingXls: false,
      };

    case FETCH_CONSENT_TYPES:
      return {
        ...state,
        fetchingConsentTypes: true,
        consentTypes: null,
        fetchConsentTypesError: null,
      };
    case FETCH_CONSENT_TYPES_SUCCESS:
      return {
        ...state,
        fetchingConsentTypes: false,
        consentTypes: action.data,
        fetchConsentTypesError: null,
      };
    case FETCH_CONSENT_TYPES_FAILED:
      return {
        ...state,
        fetchingConsentTypes: false,
        consentTypes: null,
        fetchConsentTypesError: action.error,
      };

    case SAVE_CONSENT_CSV:
      return {
        ...state,
        savingConsentEntry: true,
        showConsentSaveModal: true,
        checkConsentResult: null,
        consentSaveError: null,
      };

    case SAVE_CONSENT_CSV_SUCCESS:
      return {
        ...state,
        savingConsentEntry: false,
        consentSaveError: null,
      };

    case SAVE_CONSENT_CSV_FAILED:
      return {
        ...state,
        savingConsentEntry: false,
        showConsentSaveModal: false,
        consentSaveError: action.error,
      };

    case SAVE_CONSENT_ENTRY:
      return {
        ...state,
        savingConsentEntry: true,
        showConsentSaveModal: true,
        checkConsentResult: null,
        consentSaveError: null,
      };

    case SAVE_CONSENT_ENTRY_SUCCESS:
      return {
        ...state,
        savingConsentEntry: false,
        consentSaveError: null,
      };

    case SAVE_CONSENT_ENTRY_FAILED:
      return {
        ...state,
        savingConsentEntry: false,
        showConsentSaveModal: false,
        consentSaveError: action.error,
      };

    case CHECK_CONSENT_ENTRY:
      return {
        ...state,
        checkConsentResult:  null,
        consentSaveError: null,
      };

    case CHECK_CONSENT_ENTRY_SUCCESS:
      return {
        ...state,
        checkConsentResult:  action.data,
        consentSaveError: null,
      };

    case CHECK_CONSENT_ENTRY_FAILED:
      return {
        ...state,
        consentSaveError: action.error,
      };

    case CLEAR_CONSENT_SAVE_ERROR:
      return {
        ...state,
        consentSaveError: null,
      };

    case CLOSE_DELETE_CONFIRM_MODAL:
      return {
        ...state,
        showDeleteConfirmModal: false,
      };

    case CLOSE_SAVE_CONSENT_MODAL:
      return {
        ...state,
        showConsentSaveModal: false,
      };

    case DELETE_RECORD:
      return {
        ...state,
        deletingRecord: true,
        showDeleteConfirmModal: true,
      };

    case DELETE_RECORD_SUCCESS:
      return {
        ...state,
        deletingRecord: false,
        deleteRecordError: null,
      };

    case DELETE_RECORD_FAILED:
      return {
        ...state,
        deletingRecord: false,
        deleteRecordError: action.error,
      };

    case CLEAR_DELETE_RECORD_ERROR:
      return {
        ...state,
        deleteRecordError: null,
      };

    default:
      return state;
  }
};
