import axios from "axios";
import FileSaver from 'file-saver';

import moment from "moment";

import { authIsMissing } from "./auth";

export const FETCH_ACTIVE_SENDERS_DATA =
  "ACTIVE_SENDERS/FETCH_ACTIVE_SENDERS_DATA";
export const FETCH_ACTIVE_SENDERS_DATA_SUCCESS =
  "ACTIVE_SENDERS/FETCH_ACTIVE_SENDERS_DATA_SUCCESS";
export const FETCH_ACTIVE_SENDERS_DATA_FAILED =
  "ACTIVE_SENDERS/FETCH_ACTIVE_SENDERS_DATA_FAILED";

export const FETCH_ACTIVE_SENDERS_XLS =
  "ACTIVE_SENDERS/FETCH_ACTIVE_SENDERS_XLS";
export const FETCH_ACTIVE_SENDERS_XLS_SUCCESS =
  "ACTIVE_SENDERS/FETCH_ACTIVE_SENDERS_XLS_SUCCESS";
export const FETCH_ACTIVE_SENDERS_XLS_FAILED =
  "ACTIVE_SENDERS/FETCH_ACTIVE_SENDERS_XLS_FAILED";

export const FETCH_CONSENT_STATS_FOR_SENDER =
  "ACTIVE_SENDERS/FETCH_CONSENT_STATS_FOR_SENDER";

export const FETCH_CONSENT_STATS_FOR_SENDER_SUCCESS =
  "ACTIVE_SENDERS/FETCH_CONSENT_STATS_FOR_SENDER_SUCCESS";

export const FETCH_CONSENT_STATS_FOR_SENDER_FAILED =
  "ACTIVE_SENDERS/FETCH_CONSENT_STATS_FOR_SENDER_FAILED";

export const FETCH_GLOBAL_CONSENT_STATS =
  "ACTIVE_SENDERS/FETCH_GLOBAL_CONSENT_STATS";
export const FETCH_GLOBAL_CONSENT_STATS_SUCCESS =
  "ACTIVE_SENDERS/FETCH_GLOBAL_CONSENT_STATS_SUCCESS";
export const FETCH_GLOBAL_CONSENT_STATS_FAILED =
  "ACTIVE_SENDERS/FETCH_GLOBAL_CONSENT_STATS_FAILED";

export const SAVE_SENDER_CASL = "ACTIVE_SENDERS/SAVE_SENDER_CASL";
export const SAVE_SENDER_CASL_FAILED = "ACTIVE_SENDERS/SAVE_SENDER_CASL_FAILED";
export const SAVE_SENDER_CASL_SUCCESS = "ACTIVE_SENDERS/SAVE_SENDER_CASL_SUCCESS";
export const CLEAR_ERRORS = "ACTIVE_SENDERS/CLEAR_ERRORS";

const initialState = {
  loading: false,
  data: [],
  error: null,
  startDate: null,
  endDate: null,
  sender: "",
  pages: -1,
  pageSize: 10,
  consentStatsForSender: [],
  loadingConsentForSender: false,
  consentLoadForSenderError: null,
  globalConsentStats: [],
  loadingGlobalConsentStats: false,
  globalConsentStatsLoadError: null,
  fetchingXls: false,
};

let abortController; // Module scoped abort controller
export const fetchData = (dispatch) => (
  startDate,
  endDate,
  sender,
  page,
  pageSize,
  domain
) => {
  dispatch({
    type: FETCH_ACTIVE_SENDERS_DATA,
  });
  const accessToken = sessionStorage.getItem("accessToken");

  if (
    accessToken === null ||
    accessToken === "" ||
    accessToken === "undefined"
  ) {
    sessionStorage.removeItem("accessToken");
    return dispatch(authIsMissing());
  }

  const params = {
    page,
    pageSize,
  };

  // add domain if not default
  if (!!domain && domain !== -1) {
    params["domainId"] = domain;
  }

  if (startDate !== null) {
    params["dateFrom"] = moment(startDate).format("YYYY-MM-DD");
  }

  if (endDate !== null) {
    params["dateTo"] = moment(endDate).format("YYYY-MM-DD");
  }

  if (sender !== "") {
    params["sender"] = sender;
  }

  if (abortController) {
    abortController.abort(); // Tell the browser to abort request
  }
  abortController = typeof 'AbortController' !== 'undefined' && new AbortController();

  return axios
    .get(`${process.env.REACT_APP_API_HOST}/sender`, {
      params,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      signal: abortController.signal,
    })
    .then((res) => {
      // add index to each row
      const quarantineData = res.data.data.results.map((row, idx) => {
        return { ...row, rowIndex: idx };
      });
      return dispatch({
        type: FETCH_ACTIVE_SENDERS_DATA_SUCCESS,
        data: quarantineData,
        pages: res.data.data.totalCount,
      });
    })
    .catch((err) => {
      if (
        err !== undefined &&
        err.response !== undefined &&
        err.response.status === 401
      ) {
        // lets authenticate again
        sessionStorage.removeItem("accessToken");
        return dispatch(authIsMissing());
      }
      return dispatch({
        type: FETCH_ACTIVE_SENDERS_DATA_FAILED,
        error: err,
      });
    });
};


export const fetchXls = (dispatch) => (
  startDate,
  endDate,
  sender,
  domain
) => {
  dispatch({
    type: FETCH_ACTIVE_SENDERS_XLS,
  });
  const accessToken = sessionStorage.getItem("accessToken");

  if (
    accessToken === null ||
    accessToken === "" ||
    accessToken === "undefined"
  ) {
    sessionStorage.removeItem("accessToken");
    return dispatch(authIsMissing());
  }

  const params = {
    export: 1
  };

  // add domain if not default
  if (!!domain && domain !== -1) {
    params["domainId"] = domain;
  }

  if (startDate !== null) {
    params["dateFrom"] = moment(startDate).format("YYYY-MM-DD");
  }

  if (endDate !== null) {
    params["dateTo"] = moment(endDate).format("YYYY-MM-DD");
  }

  if (sender !== "") {
    params["sender"] = sender;
  }

  return axios
    .get(`${process.env.REACT_APP_API_HOST}/sender`, {
      params,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      responseType: 'blob',
    })
    .then((res) => {

      FileSaver.saveAs(res.data, "activeSenders.csv");

      return dispatch({
        type: FETCH_ACTIVE_SENDERS_XLS_SUCCESS,
      });
    })
    .catch((err) => {
      if (
        err !== undefined &&
        err.response !== undefined &&
        err.response.status === 401
      ) {
        // lets authenticate again
        sessionStorage.removeItem("accessToken");
        return dispatch(authIsMissing());
      }
      return dispatch({
        type: FETCH_ACTIVE_SENDERS_XLS_FAILED,
        error: err,
      });
    });
};

let abortController2; // Module scoped abort controller
export const fetchConsentStats = (dispatch) => (
  sender,
  startDate,
  endDate,
  domain
) => {
  let [load, success, failed] = !!sender
    ? [
        FETCH_CONSENT_STATS_FOR_SENDER,
        FETCH_CONSENT_STATS_FOR_SENDER_SUCCESS,
        FETCH_CONSENT_STATS_FOR_SENDER_FAILED,
      ]
    : [
        FETCH_GLOBAL_CONSENT_STATS,
        FETCH_GLOBAL_CONSENT_STATS_SUCCESS,
        FETCH_GLOBAL_CONSENT_STATS_FAILED,
      ];

  dispatch({
    type: load,
  });
  const accessToken = sessionStorage.getItem("accessToken");

  if (
    accessToken === null ||
    accessToken === "" ||
    accessToken === "undefined"
  ) {
    sessionStorage.removeItem("accessToken");
    return dispatch(authIsMissing());
  }

  const params = {};

  // add domain if not default
  if (!!domain && domain !== -1) {
    params["domainId"] = domain;
  }

  if (startDate !== null) {
    params["dateFrom"] = moment(startDate).format("YYYY-MM-DD");
  }

  if (endDate !== null) {
    params["dateTo"] = moment(endDate).format("YYYY-MM-DD");
  }

  if (sender !== "") {
    params["senderId"] = sender;
  }

  if (abortController2) {
    abortController2.abort(); // Tell the browser to abort request
  }
  abortController2 = typeof 'AbortController' !== 'undefined' && new AbortController();

  return axios
    .get(`${process.env.REACT_APP_API_HOST}/consent/stats`, {
      params,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      signal: abortController2.signal,
    })
    .then((res) => {
      return dispatch({
        type: success,
        data: res.data.data.results,
      });
    })
    .catch((err) => {
      if (
        err !== undefined &&
        err.response !== undefined &&
        err.response.status === 401
      ) {
        // lets authenticate again
        sessionStorage.removeItem("accessToken");
        return dispatch(authIsMissing());
      }
      return dispatch({
        type: failed,
        error: err,
      });
    });
};

export const saveCaslData = (dispatch) => (data) => {
  dispatch({
    type: SAVE_SENDER_CASL,
  });

  const accessToken = sessionStorage.getItem("accessToken");

  if (
    accessToken === null ||
    accessToken === "" ||
    accessToken === "undefined"
  ) {
    sessionStorage.removeItem("accessToken");
    return dispatch(authIsMissing());
  }

  return axios({
    method: "post",
    url: `${process.env.REACT_APP_API_HOST}/sender/casl`,
    data,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
    },
  })
    .then((res) => {
      return dispatch({
        type: SAVE_SENDER_CASL_SUCCESS,
      });
    })
    .catch((err) => {
      if (
        err !== undefined &&
        err.response !== undefined &&
        err.response.status === 401
      ) {
        // lets authenticate again
        sessionStorage.removeItem("accessToken");
        return dispatch(authIsMissing());
      }
      return dispatch({
        type: SAVE_SENDER_CASL_FAILED,
        error: err,
      });
    });
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        consentLoadForSenderError: null,
        globalConsentStatsLoadError: null,
        saveCaslError: action.error,
      };    
    case FETCH_ACTIVE_SENDERS_DATA:
      return {
        ...state,
        loading: true,
        data: [],
        error: null,
      };
    case FETCH_ACTIVE_SENDERS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        pages: action.pages,
        error: null,
      };
    case FETCH_ACTIVE_SENDERS_DATA_FAILED:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.error,
      };

    case FETCH_ACTIVE_SENDERS_XLS:
      return {
        ...state,
        fetchingXls: true,
      };
    case FETCH_ACTIVE_SENDERS_XLS_SUCCESS:
      return {
        ...state,
        fetchingXls: false,
      };
    case FETCH_ACTIVE_SENDERS_XLS_FAILED:
      return {
        ...state,
        fetchingXls: false,
      };

    case FETCH_CONSENT_STATS_FOR_SENDER:
      return {
        ...state,
        loadingConsentForSender: true,
        consentLoadForSenderError: null,
        consentStatsForSender: [],
      };

    case FETCH_CONSENT_STATS_FOR_SENDER_SUCCESS:
      return {
        ...state,
        loadingConsentForSender: false,
        consentLoadForSenderError: null,
        consentStatsForSender: action.data,
      };

    case FETCH_CONSENT_STATS_FOR_SENDER_FAILED:
      return {
        ...state,
        loadingConsentForSender: false,
        consentLoadForSenderError: action.error,
        consentStatsForSender: [],
      };

    case FETCH_GLOBAL_CONSENT_STATS:
      return {
        ...state,
        loadingGlobalConsentStats: true,
        globalConsentStatsLoadError: null,
        globalConsentStats: [],
      };

    case FETCH_GLOBAL_CONSENT_STATS_SUCCESS:
      return {
        ...state,
        loadingGlobalConsentStats: false,
        globalConsentStatsLoadError: null,
        globalConsentStats: action.data,
      };

    case FETCH_GLOBAL_CONSENT_STATS_FAILED:
      return {
        ...state,
        loadingGlobalConsentStats: false,
        globalConsentStatsLoadError: action.error,
        globalConsentStats: [],
      };

    case SAVE_SENDER_CASL:
      return {
        ...state,
        savingCasl: true,
        saveCaslError: null,
      };

    case SAVE_SENDER_CASL_SUCCESS:
      return {
        ...state,
        savingCasl: false,
        saveCaslError: null,
      };

    case SAVE_SENDER_CASL_FAILED:
      return {
        ...state,
        savingCasl: false,
        saveCaslError: action.error,
      };
        
    default:
      return state;
  }
};
