import axios from "axios";
import FileSaver from 'file-saver';

import moment from "moment";

import { authIsMissing } from "./auth";

export const FETCH_AUDIT_DATA = "AUDIT/FETCH_AUDIT_DATA";
export const FETCH_AUDIT_DATA_SUCCESS = "AUDIT/FETCH_AUDIT_DATA_SUCCESS";
export const FETCH_AUDIT_DATA_FAILED = "AUDIT/FETCH_AUDIT_DATA_FAILED";

export const FETCH_AUDIT_XLS = "AUDIT/FETCH_AUDIT_XLS";
export const FETCH_AUDIT_XLS_SUCCESS = "AUDIT/FETCH_AUDIT_XLS_SUCCESS";
export const FETCH_AUDIT_XLS_FAILED = "AUDIT/FETCH_AUDIT_XLS_FAILED";

export const FETCH_SMTP_LOG = "AUDIT/FETCH_SMTP_LOG";

export const FETCH_SMTP_LOG_SUCCESS = "AUDIT/FETCH_SMTP_LOG_SUCCESS";

export const FETCH_SMTP_LOG_FAILED = "AUDIT/FETCH_SMTP_LOG_FAILED";

export const SET_START_DATE = "AUDIT/SET_START_DATE";
export const SET_END_DATE = "AUDIT/SET_END_DATE";
export const CLEAR_ERRORS = "AUDIT/CLEAR_ERRORS";

export const SET_SENDER_RECIPIENT = "AUDIT/SET_SENDER_RECIPIENT";

const initialState = {
  loading: false,
  data: [],
  error: null,
  startDate: null,
  endDate: null,
  sender: "",
  recipient: "",
  pages: -1,
  pageSize: 20,
  smtpLogs: null,
  fetchingSMTPLogs: false,
  smtpLogFetchError: null,
  smtpLogID: null,
  fetchingXls: false,
};

export const setSenderAndRecipient = (sender, recipient) => {
  return {
    type: SET_SENDER_RECIPIENT,
    sender,
    recipient,
  };
};

let abortController; // Module scoped abort controller
export const fetchData = (dispatch) => (
  startDate,
  endDate,
  sender,
  recipient,
  page,
  pageSize,
  domain
) => {
  dispatch({
    type: FETCH_AUDIT_DATA,
  });

  const accessToken = sessionStorage.getItem("accessToken");
  if (
    accessToken === null ||
    accessToken === "" ||
    accessToken === "undefined"
  ) {
    sessionStorage.removeItem("accessToken");
    return dispatch(authIsMissing());
  }
  let params = {
    sender,
    recipient,
    page,
    pageSize,
  };

  if (!!domain && domain !== -1) {
    params["domainId"] = domain;
  }

  if (startDate !== null && endDate != null) {
    params["dateFrom"] = moment(startDate).format("YYYY-MM-DD");
    params["dateTo"] = moment(endDate).format("YYYY-MM-DD");
  }

  if (abortController) {
    abortController.abort(); // Tell the browser to abort request
  }
  abortController = typeof 'AbortController' !== 'undefined' && new AbortController();

  return axios
    .get(`${process.env.REACT_APP_API_HOST}/audit`, {
      params,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      signal: abortController.signal,      
    })
    .then((res) => {
      const auditData = res.data.data.results.map((row, idx) => {
        return { ...row, rowIndex: idx };
      });
      return dispatch({
        type: FETCH_AUDIT_DATA_SUCCESS,
        data: auditData,
        pages: res.data.data.pageCount,
        pageSize: res.data.data.pageSize,
      });
    })
    .catch((err) => {
      if (
        err !== undefined &&
        err.response !== undefined &&
        err.response.status === 401
      ) {
        // lets authenticate again
        sessionStorage.removeItem("accessToken");
        return dispatch(authIsMissing());
      }
      return dispatch({
        type: FETCH_AUDIT_DATA_FAILED,
        error: err,
      });
    });
};


export const fetchXls = (dispatch) => (
  startDate,
  endDate,
  sender,
  recipient,
  domain
) => {
  dispatch({
    type: FETCH_AUDIT_XLS,
  });

  const accessToken = sessionStorage.getItem("accessToken");
  if (
    accessToken === null ||
    accessToken === "" ||
    accessToken === "undefined"
  ) {
    sessionStorage.removeItem("accessToken");
    return dispatch(authIsMissing());
  }
  let params = {
    sender,
    recipient,
    export: 1
  };

  if (!!domain && domain !== -1) {
    params["domainId"] = domain;
  }

  if (startDate !== null && endDate != null) {
    params["dateFrom"] = moment(startDate).format("YYYY-MM-DD");
    params["dateTo"] = moment(endDate).format("YYYY-MM-DD");
  }

  return axios
    .get(`${process.env.REACT_APP_API_HOST}/audit`, {
      params,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      responseType: 'blob',
    })
    .then((res) => {

      FileSaver.saveAs(res.data, "audit.csv");

      return dispatch({
        type: FETCH_AUDIT_XLS_SUCCESS,
      });
    })
    .catch((err) => {
      if (
        err !== undefined &&
        err.response !== undefined &&
        err.response.status === 401
      ) {
        // lets authenticate again
        sessionStorage.removeItem("accessToken");
        return dispatch(authIsMissing());
      }
      return dispatch({
        type: FETCH_AUDIT_XLS_FAILED,
        error: err,
      });
    });
};

export const fetchSMTPLogs = (dispatch) => (id) => {
  dispatch({
    type: FETCH_SMTP_LOG,
    id,
  });

  const accessToken = sessionStorage.getItem("accessToken");
  if (
    accessToken === null ||
    accessToken === "" ||
    accessToken === "undefined"
  ) {
    return dispatch(authIsMissing());
  }

  return axios
    .get(`${process.env.REACT_APP_API_HOST}/audit/log`, {
      params: {
        id,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then((res) => {
      return dispatch({
        type: FETCH_SMTP_LOG_SUCCESS,
        data: res.data.data,
      });
    })
    .catch((err) => {
      if (
        err !== undefined &&
        err.response !== undefined &&
        err.response.status === 401
      ) {
        // lets authenticate again
        sessionStorage.removeItem("accessToken");
        return dispatch(authIsMissing());
      }
      return dispatch({
        type: FETCH_SMTP_LOG_FAILED,
        error: err,
      });
    });
};

export const setStartDate = (d) => {
  return {
    type: SET_START_DATE,
    data: d,
  };
};

export const setEndDate = (d) => {
  return {
    type: SET_END_DATE,
    data: d,
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        smtpLogFetchError: null,
      };
    case FETCH_AUDIT_DATA:
      return {
        ...state,
        loading: true,
        data: [],
        error: null,
      };
    case FETCH_AUDIT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        pages: action.pages,
        error: null,
      };
    case FETCH_AUDIT_DATA_FAILED:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.error,
      };

    case FETCH_AUDIT_XLS:
      return {
        ...state,
        fetchingXls: true,
      };
    case FETCH_AUDIT_XLS_SUCCESS:
      return {
        ...state,
        fetchingXls: false,
      };
    case FETCH_AUDIT_XLS_FAILED:
      return {
        ...state,
        fetchingXls: false,
      };

    case SET_START_DATE:
      return {
        ...state,
        startDate: action.data,
      };

    case SET_END_DATE:
      return {
        ...state,
        endDate: action.data,
      };
    case SET_SENDER_RECIPIENT:
      return {
        ...state,
        sender: action.sender,
        recipient: action.recipient,
      };

    case FETCH_SMTP_LOG:
      return {
        ...state,
        fetchingSMTPLogs: true,
        smtpLogs: null,
        smtpLogId: action.id,
        smtpLogFetchError: null,
      };

    case FETCH_SMTP_LOG_SUCCESS:
      return {
        ...state,
        fetchingSMTPLogs: false,
        smtpLogs: action.data,
      };

    case FETCH_SMTP_LOG_FAILED:
      return {
        ...state,
        fetchingSMTPLogs: false,
        smtpLogs: null,
        smtpLogFetchError: action.error,
      };

    default:
      return state;
  }
};
